"use client";

import Button from "@/components/Elements/Button";
import TableAccordion from "@/components/Elements/TableAccordion";
import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import useSWR, { mutate } from "swr";
import useUserStore from "@/stores/useUserStore";
import {
  deleteUserAddress,
  getFreightAvailableInvoices,
  getFreightDict,
  getFreightRequests,
  getUserAddresses,
} from "@/actions/user";
import Skeleton from "react-loading-skeleton";
import NotFoundText from "@/components/NotFoundText";
import PostNord_Logo from "@/images/transport/postnord.png";
import DHL_Logo from "@/images/transport/dhl.png";
import Schenker_Logo from "@/images/transport/schenker.png";
import Image, { StaticImageData } from "next/image";
import CustomPagination from "../../../components/CustomPagination";
import useCustomPagination from "@/hooks/useCustomPagination";
import useModalStore from "@/hooks/useModalStore";
import Modal from "@/components/Modal/Modal";
import AddressHandler from "./modal/AddressHandler";
import FreightProposal from "./modal/FreightProposal";
import toast from "react-hot-toast";
import FreightRequest from "./modal/FreightRequest";
import { formatPrice, parseToDate } from "@/lib/utils";
import ModalElement from "@/components/Modal/Modal";
import "react-loading-skeleton/dist/skeleton.css";
import { MD5 } from "crypto-js";

const formatTransporter = (transportName: string): JSX.Element => {
  // Map transport names to images
  const transportLogoMap: { [key: string]: StaticImageData } = {
    PostNord: PostNord_Logo,
    "DB Schenker": Schenker_Logo,
    DHL: DHL_Logo,
  };

  const logo = transportLogoMap[transportName]; // Try to get the logo

  if (!logo) {
    console.warn(`No logo found for transporter: ${transportName}`);
    return <span className="text-gray-500">Ingen leverantör vald ännu</span>; // Fallback UI
  }

  return <img src={logo.src} alt={transportName} className="w-24" />;
};

const formatLastInvoiceDate = (lastDate: string): string => {
  if (!lastDate) return "N/A";

  const date = parseToDate(lastDate);

  if (isNaN(date.getTime())) {
    return "N/A";
  }

  return date.toLocaleDateString("sv-SE");
};

const formatAddress = (jsonString: string): any => {
  try {
    // Parse the JSON string
    const address = JSON.parse(jsonString);

    // Format the address
    return (
      <>
        {address.addressName}
        <br />
        {address.addressAddress}, {address.addressZipCode} {address.addressCity}
        {/* <br />
        Tel: {address.addressPhone}
        <br />
        E-post: {address.addressEmail} */}
      </>
    );
  } catch (error) {
    console.error("Error parsing or formatting address JSON:", error);
    return "Ogiltlig address, kontakta support.";
  }
};

const getFreightStatus = (
  requestStatus: string | undefined,
  freightDict: any
): {
  icon: string | null;
  text: string | null;
  color: string | null;
  displayHTML: any;
} => {
  if (!requestStatus || !freightDict?.entFreightRequest?.requestStatus) {
    return { icon: null, text: null, color: null, displayHTML: "N/A" };
  }

  const statusIcons = freightDict.entFreightRequest.requestStatus.icons;
  const statusTexts = freightDict.entFreightRequest.requestStatus.values;
  const statusColors = freightDict.entFreightRequest.requestStatus.colors;

  const icon = statusIcons?.[requestStatus] || null;
  const text = statusTexts?.[requestStatus] || null;

  // Map raw colors to Tailwind classes
  const colorClassMap: { [key: string]: string } = {
    black: "text-slate-gray-dark",
    red: "text-danger",
    green: "text-success-green",
  };

  const rawColor = statusColors?.[requestStatus] || null;
  const color = rawColor
    ? colorClassMap[rawColor] || "text-slate-gray-dark"
    : null;

  const displayHTML = (
    <span className={`${color || ""} font-bold`}> {text}</span>
  );

  return { icon, text, color, displayHTML };
};

const getInvoiceStatusText = (invoice: any) => {
  const status = invoice?.invoiceStatus;
  const amount = invoice?.invoiceTotalAmount;

  if (["unpaid", "partpaid"].includes(status) && amount < 0) {
    return "Outnyttjad";
  }

  if (status == "paid" && amount < 0) {
    return "Utnyttjad";
  }

  switch (status) {
    case "unpaid":
      return "Obetald";
    case "paid":
      return "Betald";
    case "partpaid":
      return "Delbetald";
    case "pending":
      return "Väntande";
    default:
      return "Ohanterad";
  }
};

const Freight = ({ block, blockStyle, extraClass }: BlockProps) => {
  const [selectedRowsAddresses, setSelectedRowsAddresses] = useState([]);
  const [displayedRowAddresses, setDisplayedRowAddresses] = useState([]);

  const [selectedRowsNewRequests, setSelectedRowsNewRequests] = useState([]);
  const [displayedRowsNewRequests, setDisplayedRowsNewRequests] = useState([]);

  const [displayedFreightRequests, setDisplayedFreightRequests] = useState([]);

  const { myAccountModal } = useModalStore();

  const { user, setFetchKeys, fetchKeys } = useUserStore();
  const userId = user?.id;

  const [paginationArgsNewRequests, setPaginationArgsNewRequests] = useState({
    page: 1,
    size: 10,
    total: 0,
  });

  const [paginationArgsAddresses, setPaginationArgsAddresses] = useState({
    page: 1,
    size: 10,
    total: 0,
  });

  const [paginationArgsFreightRequests, setPaginationArgsFreightRequests] =
    useState({
      page: 1,
      size: 10,
      total: 0,
    });

  const modalReducer = (state: any, action: any) => {
    switch (action.type) {
      case "SET_VIEW":
        return { ...state, view: action.payload };
      case "SET_MODAL_TITLE":
        return { ...state, modalTitle: action.payload };
      default:
        return state;
      case "SET_MODAL":
        return {
          ...state,
          ...action.payload,
        };
      case "CLOSE_MODAL":
        return {
          ...state,
          isOpen: false,
          modalTitle: "",
          modalBody: "",
          modalSize: "lg",
        };
    }
  };

  const fetchKeyUser = fetchKeys?.userInformation;

  const [state, dispatch] = React.useReducer(modalReducer, {
    isOpen: false,
    modalTitle: "",
    modalBody: "",
    modalSize: "lg",
  });

  const {
    data: freightData,
    error,
    isLoading,
  } = useSWR<any, Error>(
    userId ? ["api/getUserFreightData", userId, fetchKeyUser] : null,
    async () => {
      const freightRequests = await getFreightRequests(fetchKeyUser);
      const freightAddresses = await getUserAddresses(fetchKeyUser);
      const availableInvoices = await getFreightAvailableInvoices();
      const freightDict = await getFreightDict();

      setPaginationArgsFreightRequests((prev: any) => ({
        ...prev,
        total: freightRequests?.length,
      }));

      setPaginationArgsAddresses((prev: any) => ({
        ...prev,
        total: freightAddresses?.length,
      }));

      setPaginationArgsNewRequests((prev: any) => ({
        ...prev,
        total: 0,
      }));

      return {
        freightRequests: freightRequests || [],
        freightDict: freightDict || {},
        freightAddresses: freightAddresses || [],
        freightAvailableInvoices: availableInvoices || [],
      };
    },
    {
      revalidateOnFocus: false,
    }
  );

  const editAddress = (address: any) => {
    myAccountModal.onOpen();

    dispatch({
      type: "SET_MODAL",
      payload: {
        modalTitle: !address ? "Lägg till address" : "Redigera adress",
        modalSize: "lg",
        modalBody: (
          <AddressHandler
            addressObject={address}
            closeModal={myAccountModal.onClose}
            afterUpdate={() => {
              myAccountModal.onClose();
              setFetchKeys({
                userInformation: MD5(
                  `userInformation-${Math.random()
                    .toString(36)
                    .substring(7)}-${userId}`
                ).toString(),
              });
              mutate(["api/getUserFreightData", userId]);
            }}
          />
        ),
      },
    });
  };

  const openFreightProposal = (freightInfo: any) => {
    myAccountModal.onOpen();

    dispatch({
      type: "SET_MODAL",
      payload: {
        modalTitle: "Fraktförslag",
        modalSize: "lg",
        modalBody: (
          <FreightProposal
            userId={userId}
            freightInfo={freightInfo}
            freightData={freightData}
            closeModal={myAccountModal.onClose}
          />
        ),
      },
    });
  };

  const deleteMultipleAddresses = async () => {
    if (!selectedRowsAddresses.length) return;

    if (selectedRowsAddresses.length === userAddresses.length) {
      toast.error("Du kan inte radera alla adresser.", {
        id: "removeAddress",
      });
      return;
    }

    const confirmDelete = confirm(
      `Är du säker på att du vill radera valda adresser (${selectedRowsAddresses.length})?`
    );

    if (!confirmDelete) return;

    try {
      // Map deletion requests into promises
      const deletionPromises = selectedRowsAddresses.map(
        async (address: any) => {
          const deleteAddress = await deleteUserAddress(address?.values?.id);

          if (deleteAddress) {
            return address?.values?.adress; // Track success for this address
          } else {
            toast.error(
              `Kunde inte radera adress (${address.adress}), försök igen senare.`,
              {
                id: "removeAddress",
              }
            );
            return null; // Indicate failure for this address
          }
        }
      );

      // Execute all deletion requests in parallel
      const results = await Promise.all(deletionPromises);

      // Filter out successful deletions
      const successfulDeletes = results.filter((result) => result !== null);

      if (successfulDeletes.length > 0) {
        toast.success(`${successfulDeletes.length} adress(er) raderade.`);

        // Refresh the data
        mutate(["api/getUserFreightData", userId]);
      }
    } catch (error) {
      console.error("Error deleting addresses:", error);
      toast.error("Ett fel uppstod vid borttagning av adresser.");
    } finally {
      setFetchKeys({
        userInformation: MD5(
          `userInformation-${Math.random().toString(36).substring(7)}-${userId}`
        ).toString(),
      });
    }
  };

  const generateFreightButton = (freightInfo: any): any => {
    const requestStatus = freightInfo?.requestStatus;

    if (isLoading) {
      return (
        <div className="w-full">
          <Skeleton height={20} />
        </div>
      );
    }

    return (
      <Button
        className={classNames(
          "bg-white text-sm text-blue border-blue hover:bg-blue hover:text-white w-fit mr-auto text-center !h-auto",
          {
            "bg-blue text-white": requestStatus == "suggested",
          }
        )}
        textCenter
        onClick={() => openFreightProposal(freightInfo)}
      >
        {requestStatus == "suggested" ? "Hantera förfrågan" : "Se mer info"}
      </Button>
    );
  };

  const generateNewFreightRequestButon = (invoices: any): any => {
    if (isLoading) {
      return (
        <div className="w-full">
          <Skeleton height={20} />
        </div>
      );
    }

    return (
      <Button
        className="text-white border-blue hover:bg-blue hover:text-white w-fit mr-auto"
        textCenter
        onClick={() => {
          dispatch({
            type: "SET_MODAL",
            payload: {
              modalTitle: "Be om fraktförslag",
              modalSize: "xl",
              modalBody: (
                <FreightRequest
                  invoices={invoices}
                  addresses={freightData?.freightAddresses}
                  userId={userId}
                  closeModal={myAccountModal.onClose}
                />
              ),
            },
          });

          myAccountModal.onOpen();
        }}
      >
        Begär fraktförslag
      </Button>
    );
  };

  const generateEditAddressButton = (address: any): any => {
    if (isLoading) {
      return (
        <div className="w-full">
          <Skeleton height={20} />
        </div>
      );
    }

    return (
      <Button
        className="text-white  text-sm border-blue hover:bg-blue hover:text-white w-fit"
        onClick={() => editAddress(address)}
      >
        Redigera
      </Button>
    );
  };

  const userAddresses = useMemo(() => {
    if (!freightData?.freightAddresses?.length) return [];

    return (
      freightData?.freightAddresses?.map((address: any) => {
        return {
          id: address?.addressId,
          namn: address?.addressName,
          kontaktperson: address?.addressContactPerson || "N/A",
          adress: address?.addressAddress,
          typ: address?.addressType == "company" ? "Företag" : "Privat",
          stad: address?.addressCity,
          postnummer: address?.addressZipCode,
          edit: generateEditAddressButton(address),
        };
      }) ?? []
    );
  }, [freightData]);

  const freightRequests = useMemo(() => {
    if (!freightData?.freightRequests?.length) return [];

    return (
      freightData?.freightRequests?.map((freightInfo: any) => {
        const freightStatus = getFreightStatus(
          freightInfo?.requestStatus,
          freightData?.freightDict
        );

        const isPending = freightInfo?.requestStatus == "requested";
        const addressId = freightInfo?.requestReceiverAddressId;

        let addressJson = freightInfo?.requestReceiverAddressLiteral
          ? freightInfo?.requestReceiverAddressLiteral
          : false;

        if (!addressJson && addressId) {
          let freightAddress = JSON.stringify(
            freightData?.freightAddresses?.find(
              (address: any) => address.addressId == addressId
            ) || []
          );

          addressJson = freightAddress;
        }

        const freightAddress = formatAddress(addressJson);

        return {
          faktura: freightInfo?.invoiceNo,
          transporter: formatTransporter(freightInfo?.requestTransporter),
          address: freightAddress,
          status: freightStatus?.displayHTML || "N/A",
          price: isPending
            ? "Inväntar prisförslag"
            : `${formatPrice(freightInfo?.requestCost)}`,
          edit: generateFreightButton(freightInfo),
        };
      }) ?? []
    );
  }, [freightData]);

  const newRequests = useMemo(() => {
    if (!freightData?.freightAvailableInvoices?.length) return [];

    return (
      freightData?.freightAvailableInvoices?.map((invoice: any) => {
        return {
          invoiceNo: invoice?.invoiceNo,
          faktura: `Faktura #${invoice?.invoiceNo}`,
          information: formatLastInvoiceDate(
            invoice?.addressFreightRequestLastDate
          ),
          status: getInvoiceStatusText(invoice),
          edit: generateNewFreightRequestButon([invoice]),
        };
      }) ?? []
    );
  }, [freightData]);

  useCustomPagination({
    paginationArg: paginationArgsFreightRequests,
    allItems: freightRequests || [],
    setItems: setDisplayedFreightRequests,
  });

  useCustomPagination({
    paginationArg: paginationArgsAddresses,
    allItems: userAddresses || [],
    setItems: setDisplayedRowAddresses,
  });

  useCustomPagination({
    paginationArg: paginationArgsNewRequests,
    allItems: newRequests || [],
    setItems: setDisplayedRowsNewRequests,
  });

  const MemoizedModalElement = useMemo(
    () => (
      <ModalElement
        id="agreement-modal"
        isOpen={myAccountModal.isOpen}
        onClose={myAccountModal.onClose}
        modalTitle={state.modalTitle}
        modalBody={state.modalBody}
        modalTitleClass="!mb-4"
        modalBg="bg-gray-ultra-light"
        modalBackDropBackdropClass="fixed inset-0 z-[991] bg-white bg-opacity-30 backdrop-blur-md"
        size={state?.modalSize}
      />
    ),
    [myAccountModal.isOpen, state.modalTitle, state.modalBody, state.modalSize]
  );

  if (error) {
    <NotFoundText
      title="Inga fraktförslag"
      description="Det finns inga fraktförslag att visa."
    />;
  }

  return (
    <>
      {MemoizedModalElement}
      <div className="space-y-4 w-full mt-0 my-4 md:my-8">
        <div className="flex items-center justify-between">
          <h2 className="text-lg md:text-2xl font-semibold text-slate-gray-dark whitespace-nowrap">
            Adresser
          </h2>

          <Button
            className={classNames(
              "border-blue text-blue hover:bg-blue hover:text-white w-fit !opacity-0 pointer-events-none",
              {
                "!opacity-100 pointer-events-auto":
                  selectedRowsAddresses?.length >= 1 &&
                  displayedRowAddresses?.length > 1,
              }
            )}
            bgColor="transparent"
            onClick={deleteMultipleAddresses}
          >
            Radera markerade
          </Button>
        </div>

        {isLoading ? (
          <Skeleton />
        ) : !displayedRowAddresses?.length ? (
          <NotFoundText
            title="Inga addresser"
            description="Det finns inga addresser att visa."
          />
        ) : (
          <>
            <TableAccordion
              columns={[
                { name: "Namn", accessor: "namn" },
                { name: "Kontaktperson", accessor: "kontaktperson" },
                { name: "Adress", accessor: "adress" },
                { name: "Stad", accessor: "stad" },
                { name: "Postnummer", accessor: "postnummer" },
                { name: "Typ", accessor: "typ" },
                { name: "Alternativ", accessor: "edit" },
              ]}
              items={displayedRowAddresses}
              setSelectedRows={setSelectedRowsAddresses}
              useCheckbox={
                displayedRowAddresses && displayedRowAddresses.length > 1
              }
            />

            <CustomPagination
              paginationArgs={paginationArgsAddresses}
              setPaginationArgs={setPaginationArgsAddresses}
            />
          </>
        )}
        <span
          className="!mt-6 font-semibold cursor-pointer text-blue block"
          onClick={() => editAddress(null)}
        >
          + Lägg till adress
        </span>
      </div>

      <div className="space-y-4 w-full my-4 md:my-8">
        <div className="flex items-center justify-between">
          <div className="flex flex-row space-x-2 items-center">
            <h2 className="text-lg md:text-2xl font-semibold text-slate-gray-dark whitespace-nowrap">
              Be om fraktförslag
            </h2>
            {!selectedRowsNewRequests?.length &&
              displayedRowsNewRequests?.length > 1 && (
                <div className="flex flex-row space-x-2">
                  <Image
                    className="relative w-6 h-6 overflow-hidden shrink-0 object-cover opacity-[0.7] cursor-help"
                    alt=""
                    width="20"
                    height="20"
                    src="/icons/help.svg"
                    data-tooltip-id="default-tooltip"
                    data-tooltip-content="Markera flera fakturor för att be om fraktförslag på alla markerade."
                  />
                </div>
              )}
          </div>

          <Button
            className={classNames(
              "border-blue text-blue hover:bg-blue hover:text-white w-fit !opacity-0 pointer-events-none",
              {
                "!opacity-100 pointer-events-auto":
                  selectedRowsNewRequests?.length > 0,
              }
            )}
            bgColor="transparent"
            onClick={() => {
              const invoiceNumbers = selectedRowsNewRequests?.map(
                (row: any) => row.values.invoiceNo
              );

              const invoicesForSelect =
                freightData?.freightAvailableInvoices?.filter((invoice: any) =>
                  invoiceNumbers.includes(invoice.invoiceNo)
                );

              dispatch({
                type: "SET_MODAL",
                payload: {
                  modalTitle: "Be om fraktförslag",
                  modalSize: "xl",
                  modalBody: (
                    <FreightRequest
                      invoices={invoicesForSelect}
                      addresses={freightData?.freightAddresses}
                      userId={userId}
                      closeModal={myAccountModal.onClose}
                    />
                  ),
                },
              });

              myAccountModal.onOpen();
            }}
          >
            Be om fraktförslag på markerade
          </Button>
        </div>

        {isLoading ? (
          <Skeleton />
        ) : !displayedRowsNewRequests?.length ? (
          <NotFoundText
            title="Inga fraktförslag"
            description="Det finns inga aktuella fraktftmöjligheter för dina nuvarande fakturor."
          />
        ) : (
          <>
            <TableAccordion
              columns={[
                { name: "Faktura", accessor: "faktura" },
                { name: "Be om fraktförslag senast", accessor: "information" },
                { name: "Status", accessor: "status" },
                { name: "Alternativ", accessor: "edit" },
              ]}
              setSelectedRows={setSelectedRowsNewRequests}
              items={displayedRowsNewRequests}
              useCheckbox
            />

            <CustomPagination
              paginationArgs={paginationArgsNewRequests}
              setPaginationArgs={setPaginationArgsNewRequests}
            />
          </>
        )}
      </div>

      <div className="space-y-4 w-full my-4 md:my-8">
        <div className="flex items-center justify-between">
          <h2 className="text-lg md:text-2xl font-semibold text-slate-gray-dark my-2 whitespace-nowrap">
            Pågående fraktförfrågningar
          </h2>
        </div>

        {isLoading ? (
          <Skeleton />
        ) : !displayedFreightRequests?.length ? (
          <NotFoundText
            title="Inga pågående fraktförfrågningar"
            description="Det finns inga fraktförfrågningar att visa."
          />
        ) : (
          <>
            <TableAccordion
              columns={[
                { name: "Leverantör", accessor: "transporter" },
                { name: "Address", accessor: "address" },
                { name: "Kostnad", accessor: "price" },
                { name: "Status", accessor: "status" },
                { name: "Alternativ", accessor: "edit" },
              ]}
              items={displayedFreightRequests}
            />

            <CustomPagination
              paginationArgs={paginationArgsFreightRequests}
              setPaginationArgs={setPaginationArgsFreightRequests}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Freight;
