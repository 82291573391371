"use client";

import React, { useContext, useEffect, useRef, useState } from "react";
import useSWR from "swr";
import { userGetCompanyAliases } from "@/actions/user";
import BeatLoader from "react-spinners/BeatLoader";
import AgreementModal from "@/components/Modal/Modal";
import ProfileRow from "./ProfileRow";
import Alert from "@/components/Elements/Alert";
import { MD5 } from "crypto-js";
import useUserStore from "@/stores/useUserStore";

interface AddCompanyProfilesProps {
  userPin: number;
  companyUserId: number;
}

export const AddProfileContext = React.createContext<any>(null);

const AddCompanyProfiles: React.FC<AddCompanyProfilesProps> = ({
  userPin,
  companyUserId,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { fetchKeys, user, userAgreementStatus } = useUserStore();

  const userId = user?.id;

  const userFetchKey =
    fetchKeys?.userInformation || MD5(`userInformation-${userId}`).toString();

  const {
    data: companyAliases,
    isLoading,
    error,
  } = useSWR<any, Error>(
    companyUserId ? ["api/userGetCompanyAliases", userFetchKey] : null,
    async () => {
      const conpanyAliases = await userGetCompanyAliases(userFetchKey);
      return conpanyAliases;
    },
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
    }
  );

  const companyAliasesFiltered = companyAliases?.filter(
    (profile: any) => profile?.aliasPrimary !== 1
  );

  const [isAddingProfile, setIsAddingProfile] = useState(false);

  // useEffect(() => {
  //   if (containerRef?.current) {
  //     containerRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [isAddingProfile]);

  if (error) {
    return <>Kunde inte läsa in medarbetare. Försök igen senare.</>;
  }

  return (
    <AddProfileContext.Provider
      value={{ isAddingProfile, setIsAddingProfile, companyUserId }}
    >
      <div
        className="p-5 sm:p-14 xl:p-20 bg-aliceblue text-toast-blue font-light rounded-lg flex flex-col w-full !mb-10"
        ref={containerRef}
      >
        <h3 className="text-xl mt-4 hidden sm:block">Medarbetare</h3>

        {isLoading && <BeatLoader color="#183e4f" />}

        {isAddingProfile && (
          <div className="flex flex-col space-y-8 my-8">
            <ProfileRow />
          </div>
        )}

        {companyAliasesFiltered?.length ? (
          <div className="flex flex-col space-y-8 my-8">
            {companyAliasesFiltered.map((profile: any, index: number) => (
              <ProfileRow key={index} profile={profile} />
            ))}
          </div>
        ) : (
          !isAddingProfile && (
            <Alert className="bg-modal-blue text-white my-2">
              Du har inte lagt till några medarbetare än.
            </Alert>
          )
        )}

        {!isAddingProfile && (
          <div
            className="text-blue text-16 my-12 flex flex-row items-center space-x-3 font-bold hover:opacity-90 transition-all duration-300"
            role="button"
            onClick={() => setIsAddingProfile(true)}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M6 8H0V6H6V0H8V6H14V8H8V14H6V8Z" fill="currentColor" />
            </svg>
            <span>Lägg till medarbetare</span>
          </div>
        )}
      </div>
    </AddProfileContext.Provider>
  );
};

export default AddCompanyProfiles;
