"use client";

import React, { useCallback, useContext, useEffect } from "react";
import classNames from "classnames";

interface CustomPaginationProps {
  paginationArgs?: any;
  setPaginationArgs?: any;
  isLoading?: boolean;
  scrollRef?: React.RefObject<HTMLDivElement>;
}

const PAGE_BUFFER = 3;
const MAX_DISPLAYED_PAGES = 10;

const PaginationContext = React.createContext<any>(null);

const PaginationButton = ({ direction = "left" }) => {
  const { paginationArgs, setPaginationArgs } = useContext(PaginationContext);

  const maxPages = Math.ceil(paginationArgs.total / paginationArgs.size);

  const canGoLeft = direction == "left" && paginationArgs.page > 1;
  const canGoRight = direction == "right" && paginationArgs.page < maxPages;

  const setPage = useCallback(() => {
    if (canGoLeft) {
      setPaginationArgs((prev: any) => ({
        ...prev,
        page: paginationArgs.page - 1,
      }));
    } else if (canGoRight) {
      setPaginationArgs((prev: any) => ({
        ...prev,
        page: paginationArgs.page - 1,
      }));
    }
  }, [paginationArgs, canGoLeft, canGoRight]);

  const iconClass = classNames({
    "rotate-180": direction == "right",
    "opacity-20 pointer-events-none right": direction == "right" && !canGoRight,
    "opacity-20 pointer-events-none left": direction == "left" && !canGoLeft,
  });

  return (
    <div
      className="bg-white flex items-center p-[9px] rounded-md hover:opacity-80 transition-all duration-500 cursor-pointer"
      onClick={setPage}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={iconClass}
      >
        <path
          d="M15.7051 7.70443L11.1251 12.2944L15.7051 16.8844L14.2951 18.2944L8.29512 12.2944L14.2951 6.29443L15.7051 7.70443Z"
          fill="#2D2D32"
        />
      </svg>
    </div>
  );
};

const CustomPagination: React.FC<CustomPaginationProps> = ({
  paginationArgs,
  setPaginationArgs,
}) => {
  const pages =
    (paginationArgs && Math.ceil(paginationArgs.total / paginationArgs.size)) ||
    0;

  const currentPage = (paginationArgs && paginationArgs.page) || 1;

  const updatePath = (index: number) => {
    if (index !== currentPage) {
      changePage(index);
    }
  };

  const changePage = useCallback(
    (index: number) => {
      if (
        !paginationArgs ||
        index === currentPage ||
        index < 1 ||
        index > pages
      )
        return;

      setPaginationArgs((prev: any) => ({
        ...prev,
        page: index,
      }));
    },
    [paginationArgs, currentPage]
  );

  if (!paginationArgs || paginationArgs.size >= paginationArgs.total)
    return null;

  const generatePageNumbers = () => {
    let items = [];

    let start = Math.max(1, currentPage - PAGE_BUFFER);
    let end = Math.min(pages, currentPage + PAGE_BUFFER);

    if (end - start + 1 > MAX_DISPLAYED_PAGES - 2) {
      if (currentPage - 1 <= PAGE_BUFFER) {
        end = MAX_DISPLAYED_PAGES - 3;
      } else if (pages - currentPage <= PAGE_BUFFER) {
        start = pages - MAX_DISPLAYED_PAGES + 2;
      } else {
        start = currentPage - (MAX_DISPLAYED_PAGES - 4) / 2;
        end = currentPage + (MAX_DISPLAYED_PAGES - 4) / 2;
      }
    }

    if (start > 1) items.push("...");

    for (let i = start; i <= end; i++) {
      items.push(i);
    }

    if (end < pages - 2) items.push("...");

    if (items[items.length - 1] !== pages) {
      items.push(pages);
    }

    return items;
  };

  const allPages = generatePageNumbers().map((pageNumber: any, index) => {
    const pageClass = classNames({
      "p-3 text-center w-10 text-lg hover:bg-white rounded-md duration-500 transition cursor-pointer":
        true,
      "font-bold": currentPage === pageNumber,
    });

    if (pageNumber === "...") {
      return (
        <span key={index} className="p-3">
          ...
        </span>
      );
    }

    return (
      <span
        key={index}
        className={pageClass}
        onClick={() => updatePath(pageNumber)}
      >
        {pageNumber}
      </span>
    );
  });

  return (
    <PaginationContext.Provider value={{ paginationArgs, setPaginationArgs }}>
      <div className="max-w-5xl mx-auto flex flex-row my-14 justify-center md:max-w-screen-lg">
        <PaginationButton />
        <div className="flex flex-row flex-wrap items-center space-x-4 mx-5">
          {allPages}
        </div>
        <PaginationButton direction="right" />
      </div>
    </PaginationContext.Provider>
  );
};

export default CustomPagination;
