"use client";

import { getFreightRequest, updateFreightRequestStatus } from "@/actions/user";
import Alert from "@/components/Elements/Alert";
import Button from "@/components/Elements/Button";
import toast from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import useSWR, { mutate } from "swr";
import ReactHtmlParser from "html-react-parser";
import "react-loading-skeleton/dist/skeleton.css";
import { useEffect, useState } from "react";
import { parseDate, parseToDate } from "@/lib/utils";

interface FreightProposalProps {
  closeModal: () => void;
  freightInfo: any;
  userId: number;
  freightData: any;
}

const generateParcelString = (freightInfo: any, freightDict: any): string => {
  // Parse the requestParcelProperties JSON
  const parcelProperties = JSON.parse(freightInfo.requestParcelProperties);

  // Determine the transporter
  const transporter = freightInfo.requestTransporter;

  // Get the corresponding package code dictionary from freightDict
  const packageCodeDict =
    freightDict?.definitions?.FREIGHT_UNIFAUN_PACKAGE_CODES?.[transporter];

  if (!packageCodeDict) {
    console.error(`No package codes found for transporter: ${transporter}`);
    return "Invalid data";
  }

  // Construct the result string
  const result = parcelProperties
    .map((parcel: any) => {
      const copies = parseInt(parcel.copies, 10);
      const packageCode = parcel.packageCode;
      const weight = parseFloat(parcel.weight);

      // Translate package code
      const translatedPackage =
        packageCodeDict[packageCode] || "Unknown Package";

      // Format the output string
      return `${copies}st ${translatedPackage} á ${weight}kg`;
    })
    .join(", "); // If there are multiple parcels, separate with a comma

  return result;
};

const formatAddress = (jsonString: string): any => {
  try {
    // Parse the JSON string
    const address = JSON.parse(jsonString);

    // Format the address
    return (
      <>
        {address.addressName}
        <br />
        {address.addressAddress}, {address.addressZipCode} {address.addressCity}
      </>
    );
  } catch (error) {
    console.error("Error parsing or formatting address JSON:", error);
    return "Ogiltlig address data, kontakta support.";
  }
};

export default function FreightProposal({
  closeModal,
  freightInfo,
  freightData,
  userId,
}: FreightProposalProps) {
  const [statusIsPending, setIsPendingStatus] = useState(false);

  const requestId = freightInfo?.requestId;
  const requestStatus = freightInfo?.requestStatus;
  const isPending = requestStatus === "requested";
  const freightDict = freightData?.freightDict;

  const {
    data: freightRequest,
    error,
    isLoading,
  } = useSWR<any, Error>(
    userId ? ["api/getUserfreightRequestView", requestId, userId] : null,
    async () => {
      const freightRequest = await getFreightRequest(requestId, true);

      return freightRequest;
    },
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    if (error || (!freightRequest && !isLoading)) {
      toast.error("Ett fel uppstod vid hämtning av fraktdata.", {
        id: "freightError",
      });
      closeModal();
    }
  }, [error, freightRequest, isLoading, closeModal]);

  const requestAction = async (accepted: boolean) => {
    setIsPendingStatus(true);
    const request = await updateFreightRequestStatus(requestId, accepted);

    if (request === 1) {
      if (accepted) {
        toast.success(
          <div>
            <strong>Fraktförfrågan godkänd</strong>
            <br />
            En separat fraktfaktura skapas omgående.
            <br />
            Frakt sker när allt är betalt, och efter avslutad avhämtning.
          </div>,
          {
            position: "top-center",
            id: "freightToast",
            duration: 7000,
          }
        );
      } else {
        toast.error(
          <div>
            <strong>Fraktförfrågan avslagen</strong>
            <br />
            Fraktförfrågan har avslagits.
          </div>,
          {
            position: "top-center",
            id: "freightToast",
            duration: 7000,
          }
        );
      }

      mutate(["api/getUserFreightData", userId]);
      closeModal();
    } else {
      toast.error(
        "Ett fel uppstod vid uppdatering av förfrågan. Pröva igen senare eller kontakta oss.",
        {
          id: "freightError",
        }
      );
    }
  };

  const rowClass =
    "flex justify-between py-3 border-b border-gray-200 last:border-0 text-profile-dark-gray";
  const labelClass = "text-sm font-bold";
  const valueClass = "text-sm text-right whitespace-pre-wrap";

  if (isLoading) {
    return (
      <div className="w-full">
        <Skeleton height={50} />
      </div>
    );
  }

  if (error || (!freightRequest && !isLoading)) {
    return null;
  }
  const estimatedDeliveryDate = freightInfo.requestCalculatedDelivery
    ? !isNaN(parseDate(freightInfo.requestCalculatedDelivery))
      ? parseToDate(freightInfo.requestCalculatedDelivery).toLocaleDateString()
      : "Inte tillgängligt"
    : "Inte tillgängligt";

  const invoiceLines = freightRequest?.invoices
    ?.map((invoice: any) => invoice.invoiceLines)
    .flat();

  const buildTitle =
    invoiceLines?.map((line: any) => line?.invoiceLineTitle).join("<br/>") ||
    "Inte tillgängligt";
  ("Inte tillgängligt");

  const addressId = freightInfo?.requestReceiverAddressId;

  let addressJson = freightInfo?.requestReceiverAddressLiteral
    ? freightInfo?.requestReceiverAddressLiteral
    : false;

  if (!addressJson && addressId) {
    let freightAddress = JSON.stringify(
      freightData?.freightAddresses?.find(
        (address: any) => address.addressId == addressId
      ) || []
    );

    addressJson = freightAddress;
  }

  return (
    <div className="relative">
      <div className="mt-4 space-y-0">
        <div className={rowClass}>
          <span className={labelClass}>OBJEKT</span>
          <span className={valueClass}>{ReactHtmlParser(buildTitle)}</span>
        </div>
        <div className={rowClass}>
          <span className={labelClass}>MOTTAGARE</span>
          <span className={valueClass}>{formatAddress(addressJson)}</span>
        </div>
        <div className={rowClass}>
          <span className={labelClass}>MEDDELANDE</span>
          <span className={valueClass}>
            {ReactHtmlParser(freightInfo.requestMessage)}
          </span>
        </div>
        <div className={rowClass}>
          <span className={labelClass}>TRANSPORTÖR</span>
          <span className={valueClass}>
            {isPending
              ? "Inväntar information"
              : freightInfo.requestTransporter}
          </span>
        </div>
        <div className={rowClass}>
          <span className={labelClass}>KOLLI</span>
          <span className={valueClass}>
            {isPending
              ? "Inväntar information"
              : generateParcelString(freightInfo, freightDict)}
          </span>
        </div>
        <div className={rowClass}>
          <span className={labelClass}>BERÄKNAT LEVERANSDATUM</span>
          <span className={valueClass}>
            {isPending ? "Inväntar estimat" : estimatedDeliveryDate}
          </span>
        </div>
        <div className={rowClass}>
          <span className={labelClass}>KOSTNAD</span>
          <span className={valueClass}>
            {isPending ? "Inväntar pris" : `${freightInfo.requestCost} SEK`}
          </span>
        </div>
      </div>
      {freightInfo?.requestComment && (
        <Alert className="flex flex-col mt-5 bg-yellow text-yellow-darker">
          {freightInfo.requestComment}
        </Alert>
      )}

      <div className="mt-8 flex justify-end items-center space-x-6">
        {requestStatus == "suggested" ? (
          <>
            <Button
              className="bg-transparent font-semibold text-blue hover:bg-blue hover:text-white justify-center text-md"
              borderColor="blue"
              type="button"
              onClick={() => requestAction(false)}
              disabled={statusIsPending}
            >
              Nej, tack
            </Button>
            <Button
              className="bg-blue font-semibold text-white justify-center text-md"
              type="submit"
              onClick={() => requestAction(true)}
              disabled={statusIsPending}
            >
              Jag godkänner förfrågan
            </Button>
          </>
        ) : (
          <>
            <Button
              className="bg-transparent font-semibold text-blue hover:bg-blue hover:text-white justify-center text-md"
              borderColor="blue"
              type="button"
              onClick={() => closeModal()}
            >
              Stäng
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
