import React, { useEffect } from 'react';

interface PaginationArg {
  page: number;
  size: number;
  total: number;
}

const useCustomPagination = ({
  paginationArg,
  allItems,
  setItems
}: {
  paginationArg: PaginationArg;
  allItems: any[];
  setItems: any;
}) => {
  useEffect(() => {
    if (!paginationArg?.page || !paginationArg?.size) return;

    const offset = (paginationArg.page - 1) * paginationArg.size;
    const sliced = allItems.slice(offset, offset + paginationArg.size);

    setItems(sliced);
  }, [paginationArg.page, paginationArg.size, paginationArg.total, allItems]);
};

export default useCustomPagination;