"use client";

import Input from "@/components/Elements/Input";
import React, { useRef } from "react";

export const Client: React.FC<any> = ({ attributes }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { searchType, placeHolderText } = attributes;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault(); // Prevents the default form submission behavior
    const query = inputRef.current?.value || ""; // Fetch input value
    if (query) {
      // Construct the URL with the search query and searchType
      const url = `/sok?s=${encodeURIComponent(query)}&t=${encodeURIComponent(
        searchType
      )}`;
      window.location.href = url; // Navigate to the constructed URL
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Input
        ref={inputRef}
        type="text"
        placeholder={placeHolderText}
        icon="search"
        buttonSize="lg"
        inputClassName="focus:shadow-md"
      />
    </form>
  );
};
