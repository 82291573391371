import React from "react";
import ReactHtmlParser from "html-react-parser";

interface AuctionBlockHeadingProps {
  headingLink: string;
  headingDescription: string;
  headingTitle: string;
}

const AuctionBlockHeading: React.FC<AuctionBlockHeadingProps> = ({
  headingLink,
  headingDescription,
  headingTitle,
}) => {
  return (
    <>
      <div className="flex flex-col items-start w-full">
        {headingTitle && (
          <div className="flex flex-row space-x-4 justify-between items-center w-full">
            <h2 className="order-0 text-32 font-semibold mb-2">
              {headingTitle || ""}
            </h2>
            {/* {headingLink && (
            <div className="hidden md:flex group order-1 md:order-0 self-stretch flex flex-row flex-nowrap items-center justify-start py-[9px] px-[18px] gap-[9px]">
              <div className="relative text-toast-blue tracking-[-0.01em] cursor-pointer leading-[150%] font-semibold [&>a]:!no-underline">
                {ReactHtmlParser(headingLink || "") as string}
              </div>
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ml-2 group-hover:translate-x-2 transition-all duration-300 ease-in-out"
              >
                <path
                  d="M1.4 12L0 10.6L4.6 6L0 1.4L1.4 0L7.4 6L1.4 12Z"
                  fill="#1c354d"
                />
              </svg>
            </div>
          )} */}
          </div>
        )}
        {headingDescription && (
          <p className="fs-18 font-normal font-open-sans order-0 md:order-1 !mb-5">
            {ReactHtmlParser(headingDescription || "") as string}
          </p>
        )}
      </div>
    </>
  );
};

export default AuctionBlockHeading;
