"use client";

import React, { useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import uniqid from "uniqid";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import Checkbox from "@/components/Elements/Checkbox";
import Button from "./Button";

const AccordionContext = React.createContext<{
  columns: Array<any>;
  items: Array<any> | undefined;
  useCheckbox?: boolean;
  columnButton?: React.ReactNode;
  downloadSingleRowActions?: any;
  showRowForSingleDownload?: boolean;
  allIsSelected: boolean;
  rows: Array<any>;
  setRows: React.Dispatch<any>;
  updateFilter: (key: string) => void;
} | null>(null);

const ArrowIcon = (props: any) => {
  const { open, setOpen } = props.openProps;

  return (
    <span onClick={() => setOpen(!open)}>
      <svg
        width="22"
        height="15"
        viewBox="0 0 20 13"
        className={`${open ? "rotate-180" : ""} h-3 w-3 transition-transform`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 2.025L1.775 0.25L10 8.475L18.225 0.25L20 2.025L10 12.025L0 2.025Z"
          fill="#2D2D32"
        />
      </svg>
    </span>
  );
};

const AccordionItem: React.FC<{
  rows: any;
}> = ({ rows }) => {
  const [open, setOpen] = useState(false);

  const {
    columns,
    useCheckbox,
    columnButton,
    downloadSingleRowActions,
    updateFilter,
    setRows,
    allIsSelected,
  } = React.useContext(AccordionContext) || {};

  const { values, checked, id } = rows;

  const firstRow = (Object.values(values)[0] as string) || "";
  const secondRow = (Object.values(values)[1] as string) || "";

  if (!setRows || !updateFilter) return null;

  const accordionClass = classNames("w-full block sm:hidden mb-6", {
    "-mb-2": useCheckbox,
  });

  const gridClass = classNames(
    "bg-white grid auto-cols-fr grid-flow-col text-sm font-semibold group duration-200 transition-all",
    {
      "grid-cols-[50px]": useCheckbox,
    }
  );

  const rowClass =
    "py-4 px-6 hidden sm:flex items-center bg-white group-hover:opacity-90";

  return (
    <>
      <div className={gridClass}>
        {useCheckbox && (
          <div className={rowClass}>
            <Checkbox
              ripple={false}
              checked={checked}
              onChange={(event) => updateFilter(id)}
            />
          </div>
        )}

        {columns?.map((col, index) => (
          <div className={rowClass} key={index}>
            {values?.[col?.accessor]}
          </div>
        ))}

        {columnButton && (
          <div className="py-4 px-6 hidden sm:flex items-center bg-white group-hover:opacity-90"></div>
        )}

        {downloadSingleRowActions && (
          <div
            className={`${rowClass} md:pointer-events-none group-hover:md:opacity-1 group-hover:md:pointer-events-auto flex items-center gap-3 justify-evenly flex-wrap space-x-5`}
          >
            <div className="invisible lg:visible flex flex-col lg:flex-row flex-wrap lg:space-x-6 items-center group text-blue">
              {downloadSingleRowActions.map((action: any, index: any) => (
                <Button
                  className="text-soft-gray border-soft-gray hover:border-blue hover:text-blue w-fit !gap-0"
                  bgColor="transparent"
                  icon={action.icon}
                  size="sm"
                  key={index}
                  onClick={() => action.action(values)}
                >
                  <span>{action.label} </span>
                </Button>
              ))}
            </div>
            {/*
              Ladda ner
            </Button>

            <Button
              className="text-soft-gray border-soft-gray hover:border-blue hover:text-blue w-fit"
              bgColor="transparent"
              icon={"download"}
              size="sm"
              onClick={() => downloadSingleRowActions(values)}
            >
              Ladda ner
            </Button> */}
          </div>
        )}
      </div>

      <Accordion
        open={open}
        icon={<ArrowIcon id={1} openProps={{ open, setOpen }} />}
        className={accordionClass}
      >
        <AccordionHeader className="flex items-center px-5 justify-between py-4 border-b bg-white border-none rounded-lg w-full">
          <span className="order-0 text-gray-dark text-xl font-normal flex flex-row space-x-4 items-center">
            {useCheckbox && (
              <div className="bg-white group-hover:opacity-90 mt-2">
                <Checkbox
                  ripple={false}
                  checked={checked}
                  onChange={(event) => updateFilter && updateFilter(id)}
                />
              </div>
            )}

            <span onClick={() => setOpen(!open)}>{secondRow}</span>
          </span>
        </AccordionHeader>
        <AccordionBody className="bg-white p-5">
          <div className="flex flex-col space-y-4 font-normal">
            {columns?.map((col, index) => (
              <div className="flex flex-row justify-between" key={index}>
                <span className="text-gray-400 font-bold">{col.name}</span>
                <span className="neutral-dark-gray">
                  {values?.[col?.accessor]}
                </span>
              </div>
            ))}
          </div>
        </AccordionBody>
      </Accordion>
    </>
  );
};

interface TableAccordionInterface {
  columns: Array<any>;
  items: Array<any> | undefined;
  useCheckbox?: boolean;
  columnButton?: React.ReactNode;
  downloadSingleRowActions?: any;
  setSelectedRows?: React.Dispatch<any>;
  showRowForSingleDownload?: boolean;
}

const TableAccordion: React.FC<TableAccordionInterface> = ({
  columns,
  items,
  useCheckbox = false,
  columnButton = null,
  downloadSingleRowActions,
  showRowForSingleDownload = false,
  setSelectedRows,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const containerClass = classNames("w-full");
  const [rows, setRows] = useState<any>([]);

  const totalSelected = Object.values(rows).filter(
    (val: any) => val?.checked === true
  )?.length;

  const allIsSelected = totalSelected === items?.length;

  useEffect(() => {
    if (!setSelectedRows || !rows) return;

    const selectedAll = rows.filter((row: any) => row?.checked);
    setSelectedRows(selectedAll);
  }, [totalSelected]);

  const memoItems = useMemo(() => items, [items]);

  useEffect(() => {
    setRows(
      memoItems?.map((item) => ({
        values: item,
        checked: false,
        id: uniqid(),
      })) || []
    );
  }, [memoItems]);

  const updateFilter = (key: string) => {
    setRows((prev: any) =>
      prev.map((row: any) => {
        if (row.id === key) {
          return {
            ...row,
            checked: !row?.checked,
          };
        }
        return row;
      })
    );
  };

  const gridClass = classNames(
    "grid auto-cols-fr grid-flow-col text-sm text-muted-gray bg-white",
    {
      "grid-cols-[50px]": useCheckbox,
    }
  );

  return (
    <AccordionContext.Provider
      value={{
        columns: columns,
        items: items,
        useCheckbox,
        rows,
        setRows,
        updateFilter,
        downloadSingleRowActions,
        columnButton,
        allIsSelected,
        showRowForSingleDownload,
      }}
    >
      <div className={containerClass} ref={ref}>
        <div className="flex-none min-w-[300px] relative">
          <div className="overflow-x-auto hidden sm:block">
            <div className="overflow-x-auto">
              <div className={gridClass}>
                {useCheckbox && (
                  <div className="py-4 px-6 font-semibold text-14">
                    <Checkbox
                      ripple={false}
                      checked={allIsSelected}
                      onChange={(event) =>
                        setRows((prev: any) =>
                          prev.map((row: any) => ({
                            ...row,
                            checked: allIsSelected ? false : true,
                          }))
                        )
                      }
                    />
                  </div>
                )}

                {columns.map((col: any, index: any) => (
                  <div className="py-4 px-6 font-semibold text-14" key={index}>
                    {col.name}
                  </div>
                ))}

                {columnButton && (
                  <div className="py-4 px-6 font-semibold text-14 flex flex-row justify-end">
                    {columnButton}
                  </div>
                )}

                {/* {showRowForSingleDownload && (
                  <div className="py-4 px-6 font-semibold text-14 flex flex-row justify-end"></div>
                )} */}
              </div>
            </div>
          </div>

          {useCheckbox && (
            <div className="flex sm:hidden items-start px-5 py-4 border-b bg-white border-none rounded-lg w-full">
              <Checkbox
                ripple={false}
                checked={allIsSelected}
                onChange={(event) =>
                  setRows((prev: any) =>
                    prev.map((row: any) => ({
                      ...row,
                      checked: allIsSelected ? false : true,
                    }))
                  )
                }
              />
              <span className="ml-4 font-bold">Markera alla</span>
            </div>
          )}

          {Object.values(rows).map((row: any, index: any) => (
            <AccordionItem rows={row} key={index} />
          ))}
        </div>
      </div>
    </AccordionContext.Provider>
  );
};

export default TableAccordion;
