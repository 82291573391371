"use client";

import {
  Alert as MTAlert,
  AlertProps as MTAlertProps,
} from "@material-tailwind/react";

interface Alertrops extends MTAlertProps {}

const Alert: React.FC<Alertrops> = ({ ...alertProps }) => {
  return <MTAlert {...(alertProps as any)}>{alertProps?.children}</MTAlert>;
};

export default Alert;
