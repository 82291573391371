"use client";

import Button from "@/components/Elements/Button";
import ReactHtmlParser from "html-react-parser";
import useNewsletterHook from "@/hooks/useNewsletterHook";
import Input from "@/components/Elements/Input";
import Alert from "@/components/Elements/Alert";
import Image from "next/image";
import { useState } from "react";

interface SubscriptionFieldProps {
  icon: any;
  legalText: string;
}

export const SubscriptionField: React.FC<SubscriptionFieldProps> = ({
  icon,
  legalText,
}) => {
  const [email, setEmail] = useState<string>("");

  const {
    isLoading,
    response,
    captchaToken,
    SubscribeAction,
    ReCAPTCHAWidget,
  } = useNewsletterHook({
    email,
    setEmail,
  });

  const validateEmail = (email: string) => {
    if (!email) return true;

    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const emailSubmitted = response?.status === 200;

  return (
    <div className="flex-1 flex flex-col items-start justify-start space-y-2 w-full">
      <div className="flex flex-row items-start justify-start w-full">
        <div className="rounded-lg flex flex-col w-full md:flex-row items-start justify-start">
          {icon && (
            <div className="block md:hidden">
              <Image
                className="xl:w-[572px z-[3] max-w-[270px] object-contain my-4"
                alt="Toveks logo"
                width={150}
                height={56}
                src={icon}
              />
            </div>
          )}

          {!emailSubmitted && (
            <form className="w-full" onSubmit={SubscribeAction}>
              <div className="w-full hidden md:block md:max-w-[500px]">
                <Input
                  placeholder="Din e-postadress"
                  size="md"
                  button={
                    <Button
                      type="submit"
                      disabled={
                        !email || !captchaToken || !validateEmail(email)
                      }
                    >
                      Prenumerera
                    </Button>
                  }
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={!validateEmail(email)}
                  isLoading={isLoading}
                />
              </div>

              <div className="flex flex-col md:hidden w-full">
                <Input
                  placeholder="Din e-postadress"
                  button={
                    <Button
                      type="submit"
                      className="hidden md:block"
                      disabled={
                        !email || !captchaToken || !validateEmail(email)
                      }
                    >
                      Prenumerera
                    </Button>
                  }
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={!validateEmail(email)}
                  isLoading={isLoading}
                  fullWidth
                />
                <Button className="self-start mt-4 w-full md:w-auto justify-center py-4 md:py-0">
                  Prenumerera
                </Button>
              </div>
            </form>
          )}
        </div>
      </div>

      {email && <ReCAPTCHAWidget />}

      {response && response.status && (
        <div className="w-full">
          <div className="flex flex-col items-start justify-start gap-[12px]">
            {response?.status && (
              <Alert color={response.status === 200 ? "green" : "red"}>
                {response.message}
              </Alert>
            )}
          </div>
        </div>
      )}
      <div className="overflow-hidden flex flex-row items-start justify-start text-sm">
        <div className="relative leading-[150%] mt-2">
          {legalText && ReactHtmlParser(legalText)}
        </div>
      </div>
    </div>
  );
};
