"use client";

import { getUserAuctionItems } from "@/actions/auctions";
import { auctionFilterProps, AuctionItem } from "@/types/auction";
import React, { use, useEffect, useState } from "react";
import useSWR, { mutate } from "swr";
import { useAuctionFilter } from "@/hooks/useAuctionFilter";
import AuctionBarActionsPanelMyPages from "@/components/Auctions/AuctionBarFilter/FilterPanelMyPages";
import { getRecentItemBidsByItemIds } from "@/actions/bids";
import useUserStore from "@/stores/useUserStore";
import { MD5 } from "crypto-js";
import { EXTRA_BID_TIME, REVALIDATE_USER_AUCTIONS_TIME } from "@/lib/auctions";
import AuctionListItems from "@/components/Auctions/AuctionItem/AuctionListItems";
import { getSwedishDate } from "@/lib/utils";

export const defaultFilterPropsMyPagesAuctionItem = {
  pagination: {
    size: 25,
    page: 1,
  },
  sorting: {
    options: [
      {
        id: "itemEndTimeAsc",
        queryKey: "itemEndTime",
        matchKey: "itemEndTime",
        label: "Kortast tid kvar",
        sortOrder: "asc",
        sortBy: "itemEndTime",
      },
      {
        id: "itemEndTimeDesc",
        queryKey: "itemEndTime",
        matchKey: "itemEndTime",
        label: "Nyast",
        sortOrder: "desc",
        sortBy: "itemEndTime",
      },
      {
        id: "itemSortNoAsc",
        queryKey: "itemSortNo",
        matchKey: "itemSortNo",
        label: "Rop",
        sortOrder: "asc",
        sortBy: "itemSortNo",
      },
    ],
    selections: [
      {
        matchKey: "itemEndTime",
        queryKey: "itemEndTime",
        sortOrder: "asc",
        sortBy: "itemEndTime",
      },
    ],
  },
  filtering: {
    selections: [
      {
        queryKey: "isOngoing",
        matchKey: "isOngoing",
        value: "active",
      },
    ],
    options: [
      {
        queryKey: "isOngoing",
        matchKey: "isOngoing",
        label: "Pågående",
        value: "active",
      },
      {
        queryKey: "hasEnded",
        matchKey: "hasEnded",
        label: "Avslutade",
        value: "ended",
      },
      {
        queryKey: "bidWon",
        matchKey: "bidWon",
        label: "Vunna",
        value: "yes",
      },
    ],
  },
};

const BidsAndFavorites = ({ block, blockStyle, extraClass }: BlockProps) => {
  const [displayedItems, setDisplayedItems] = useState<any>([]);
  const auctionFilterProps = useAuctionFilter(
    defaultFilterPropsMyPagesAuctionItem
  );
  const [userAuctionItemBids, setUserAuctionItemBids] = useState<any>(false);

  const { options, setOptions, buildFilterParams } = auctionFilterProps;

  const paginationArgs = options?.pagination as any;
  const sortingArgs = options?.sorting as any;

  const optionsJson = JSON.stringify(options);

  const { user, userId, setFetchKeys, fetchKeys } = useUserStore();

  const userFetchKey =
    fetchKeys?.getUserAuctionItems || MD5(`userBids-${userId}`).toString();

  const getBidsAndFavoritesFetcher = async () => {
    const filter = buildFilterParams();

    const params = {
      offset: (paginationArgs.page - 1) * paginationArgs.size,
      limit: paginationArgs.size,
      sort: {
        [sortingArgs.selections?.[0]?.queryKey]:
          sortingArgs.selections?.[0]?.sortOrder,
      },
      filter: filter,
    };

    const { auctionItems: getAllAuctionItems, totalHits: getTotalHits } =
      await getUserAuctionItems(params, userFetchKey);

    console.log("items fetched", getAllAuctionItems);

    if (!getAllAuctionItems) return [];

    // Slice out offset from limit to get the correct items
    const displayedItems =
      getAllAuctionItems?.slice(params.offset, params.offset + params.limit) ||
      [];

    setDisplayedItems(displayedItems);

    const auctionItemIds = displayedItems?.map((item) => item.itemId) || [];

    const { allItemBids, serverTime } = await getRecentItemBidsByItemIds(
      auctionItemIds
    );
    const serverTimeLocal = getSwedishDate(serverTime);

    const allAuctionItems = displayedItems.map((item: any) => {
      const bidMatch = allItemBids.find(
        (bid: any) => bid.itemId === item.itemId
      );

      const updatedItemEndTime = bidMatch?.itemEndTime || item.itemEndTime;
      const itemEndTimestamp = new Date(updatedItemEndTime).getTime();

      return {
        ...item,
        itemEndTime: updatedItemEndTime,
        itemHasEnded:
          itemEndTimestamp + EXTRA_BID_TIME <
          new Date(serverTimeLocal).getTime(),
      };
    });

    setUserAuctionItemBids(allItemBids);

    setOptions((prevArgs: auctionFilterProps) => ({
      ...prevArgs,
      pagination: {
        ...prevArgs.pagination,
        total: getTotalHits ? getTotalHits : 0,
      },
    }));

    return allAuctionItems;
  };

  const { error, isLoading } = useSWR<AuctionItem[], Error>(
    ["api/getUserBidAndFavorites", optionsJson],
    getBidsAndFavoritesFetcher,
    {
      refreshInterval: REVALIDATE_USER_AUCTIONS_TIME,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: true,
    }
  );

  return (
    <div className="flex flex-col space-y-6 w-full" style={blockStyle}>
      <AuctionBarActionsPanelMyPages filterProps={auctionFilterProps} />

      <AuctionListItems
        visibleItemsData={displayedItems?.length}
        auctionItemBids={userAuctionItemBids}
        filterProps={auctionFilterProps}
        auctionItems={displayedItems}
        isLoading={isLoading}
        error={error}
        showFilter={false}
        searchLayout
      />
    </div>
  );
};

export default BidsAndFavorites;
