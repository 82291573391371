"use client";

import React, { useState, useEffect, useCallback } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import Button from "@/components/Elements/Button";
import toast from "react-hot-toast";
import Row from "../../my-account/Row";
import { postUserAddress, putUserAddress } from "@/actions/user";
import useDebouncedCallback from "@/hooks/useDebouncedCallback";
import useUserStore from "@/stores/useUserStore";
import { MD5 } from "crypto-js";

const AddressHandler = ({ addressObject, afterUpdate, closeModal }: any) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    trigger,
    reset, // Used to set default values dynamically
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: {
      addressName: "",
      addressContactPerson: "",
      addressAddress: "",
      addressType: "privatePerson", // Default type
    },
  });

  const addressObjectMemomized = React.useMemo(
    () => addressObject,
    [addressObject]
  );

  useEffect(() => {
    // If an addressObject is passed, set default values
    if (addressObject) {
      reset({
        addressName: addressObject.addressName || "",
        addressContactPerson: addressObject.addressContactPerson || "",
        addressAddress: addressObject.addressAddress || "",
        addressType: addressObject.addressType || "privatePerson",
        addressZipCode: addressObject.addressZipCode || "",
        addressCity: addressObject.addressCity || "",
      });
    }
  }, [addressObjectMemomized, reset]);

  const inputFields = {
    addressDetails: [
      {
        key: "addressType",
        label: "Typ",
        type: "select",
        options: [
          { label: "Privatperson", value: "privatePerson" },
          { label: "Företag", value: "company" },
        ],
        required: true,
        canEdit: true,
        validateProps: {
          required: "Typ är obligatoriskt.",
        },
      },
      {
        key: "addressName",
        label: "Namn",
        type: "text",
        required: true,
        canEdit: true,
        validateProps: {
          required: "Namn är obligatoriskt.",
        },
      },
      {
        key: "addressContactPerson",
        label: "Kontaktperson",
        type: "text",
        required: false,
        canEdit: true,
      },
      {
        key: "addressAddress",
        label: "Address",
        type: "text",
        required: true,
        canEdit: true,
        validateProps: {
          required: "Address är obligatoriskt.",
        },
      },
      {
        key: "addressZipCode",
        label: "Postnummer",
        type: "text",
        required: true,
        canEdit: true,
        validateProps: {
          required: "Postnummer är obligatoriskt.",
          validate: (value: any) => {
            const isValidZip = /^[0-9]+$/.test(value);
            return isValidZip || "Postnummer får endast innehålla siffror.";
          },
        },
      },
      {
        key: "addressCity",
        label: "Ort",
        type: "text",
        required: true,
        canEdit: true,
        validateProps: {
          required: "Ort är obligatoriskt.",
        },
      },
    ],
  };

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    try {
      let response;

      if (addressObject) {
        response = await putUserAddress(addressObject.addressId, data);
        toast.success("Address uppdaterad!");
      } else {
        response = await postUserAddress(data);
        toast.success("Address skapad!");
      }
    } catch (error) {
      toast.error("Kunde inte uppdatera address, försök igen senare.");
    } finally {
      afterUpdate();
      setIsSubmitting(false);
    }
  };

  const triggerForm = useDebouncedCallback(async () => {
    const isValid = await trigger();

    if (isValid) {
      handleSubmit(onSubmit)();
    } else {
      toast.error(
        "Formuläret innehåller fel, vänligen kontrollera formuläret.",
        { id: "form-error-toast" }
      );
      setIsSubmitting(false);
    }
  }, 500);

  const validateForm = async (e: any) => {
    e.preventDefault();
    setIsSubmitting(true);
    triggerForm();
  };

  return (
    <form
      onSubmit={validateForm}
      className="flex flex-col w-full max-w-md mx-auto"
    >
      <Row
        fields={inputFields.addressDetails}
        register={register}
        errors={errors}
        forceOpen={true}
      />

      {/* Action Buttons */}
      <div className="mt-8 flex justify-end items-center space-x-6">
        <Button
          className="bg-transparent font-semibold text-blue justify-center text-base md:text-md"
          borderColor="blue"
          type="button"
          onClick={() => closeModal()}
        >
          Avbryt
        </Button>
        <Button
          className="bg-blue font-semibold text-white justify-center text-base md:text-md"
          type="submit"
          disabled={isSubmitting}
        >
          {addressObject ? "Spara address" : "Lägg till address"}
        </Button>
      </div>
    </form>
  );
};

export default AddressHandler;
