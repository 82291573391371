"use client";

import { Auction, AuctionListProps } from "@/types/auction";
import LinkWrapper from "@/components/Elements/LinkWrapper";
import Link from "next/link";
import AuctionCard from "@/components/Auctions/Auction/Card/Index";
import Button from "@/components/Elements/Button";
import React, { useEffect } from "react";
import AuctionBarActionsPanel from "../AuctionBarFilter/ActionPanel";
import useGetThemeSettings from "@/hooks/useGetThemeSettings";
import AuctionButtonToggle from "./AuctionButtonToggle";
import NotFoundText from "@/components/NotFoundText";
import AuctionPagination from "../AuctionPagination";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const AuctionList: React.FC<AuctionListProps> = ({
  layoutType,
  auctions,
  showFilter = true,
  showSorting = true,
  readMoreButton = false,
  filterProps,
  isLoading = false,
  error = false,
  showPagination = true,
}) => {
  const scrollRef = React.useRef<any>(null);

  const layoutLabel: any = {
    all: "all",
    ongoing: "pågående",
    archive: "avslutade",
  };

  const allAuctionsUrl = "/auktioner/pagaende-auktioner";

  return (
    <>
      {/* {["ongoing", "archive"].includes(layoutType) && allAuctionsUrl && (
        <div className="hidden md:block">
          <div className="flex flex-col md:flex-row items-center justify-start gap-12">
            <LinkWrapper>
              <Link href={allAuctionsUrl}>
                Visa alla {layoutLabel[layoutType]} auktioner
              </Link>
            </LinkWrapper>
          </div>
        </div>
      )}

      {["ongoing", "archive"].includes(layoutType) && allAuctionsUrl && (
        <div className="block md:hidden mt-6">
          <LinkWrapper>
            <Link href={allAuctionsUrl}>
              Visa alla {layoutLabel[layoutType]} auktioner
            </Link>
          </LinkWrapper>
        </div>
      )} */}
      {["ongoing"].includes(layoutType) && !error ? (
        <div className="flex flex-row justify-between items-center w-full">
          <AuctionButtonToggle filterProps={filterProps} />
          {readMoreButton && (
            <div className="md:block">
              <div className="flex flex-col md:flex-row items-center justify-start gap-12">
                <LinkWrapper>
                  <Link href={allAuctionsUrl}>Visa alla auktioner</Link>
                </LinkWrapper>
              </div>
            </div>
          )}
        </div>
      ) : null}
      {["all", "archive"].includes(layoutType) ? (
        <AuctionBarActionsPanel
          showFilter={showFilter}
          filterProps={filterProps}
        />
      ) : null}

      {!!error ||
        (!isLoading && !!auctions && auctions?.length === 0 && (
          <NotFoundText
            title="Hittade inga auktioner"
            description="Inga auktioner finns att visa just nu."
          />
        ))}
      <div
        className="self-stretch overflow-x-scroll md:overflow-hidden grid grid-cols-1 md:grid-1 md:grid-cols-3 xl:grid-cols-4 items-stretch gap-6 md:gap-8 text-sm"
        ref={scrollRef}
      >
        {isLoading &&
          !error &&
          Array.from({ length: 4 }).map((_, i) => (
            <div
              className={`flex flex-col ${
                i === 3 ? "hidden xl:block xl:block" : ""
              }`}
              key={i}
            >
              <Skeleton height={352} />
              <Skeleton height={30} />
              <Skeleton height={60} />
              <Skeleton count={3} />
            </div>
          ))}

        {!isLoading && !!auctions && (auctions as any)?.length > 0 && (
          <>
            {auctions?.map((auction: Auction, index) => {
              return <AuctionCard key={index} auction={auction} />;
            })}
          </>
        )}
      </div>
      {["all", "archive", "ongoing"].includes(layoutType) &&
      !!showPagination &&
      !isLoading ? (
        <div className="flex flex-row items-center mx-auto my-4 justify-center my-5">
          <AuctionPagination filterProps={filterProps} scrollRef={scrollRef} />
        </div>
      ) : null}
      {["ongoing", "archive"].includes(layoutType) &&
        !isLoading &&
        !!allAuctionsUrl &&
        readMoreButton && (
          <div className="flex w-full items-center justify-center">
            <Link href={allAuctionsUrl} className="!no-underline">
              <Button textColor="white" bgColor="blue" icon="arrow-right">
                Visa alla auktioner
              </Button>
            </Link>
          </div>
        )}
    </>
  );
};
