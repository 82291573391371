import ButtonToggle from "@/components/Elements/ButtonToggle";
import { useAuctionFilterProps } from "@/types/auction";
import React, { useEffect } from "react";

interface AuctionButtonToggleProps {
  filterProps: useAuctionFilterProps;
}

const AuctionButtonToggle: React.FC<AuctionButtonToggleProps> = ({
  filterProps,
}: any) => {
  const { options, setOptions } = filterProps || {};
  const [activeMenuValue, setActiveMenuValue] =
    React.useState<any>("partAuctionStart");

  const setSortingValue = (object: any) =>
    setOptions((prevOptions: any) => ({
      ...prevOptions,
      sorting: {
        ...prevOptions.sorting,
        selections: [object],
      },
    }));

  const setFilteringValue = (object: any) => {
    setOptions((prevOptions: any) => ({
      ...prevOptions,
      filtering: {
        ...prevOptions.filtering,
        selections: [object],
      },
    }));
  };

  const buttons = [
    {
      label: "Slutar snart",
      onClick: () => {
        setSortingValue({
          sortBy: "partAuctionStart",
          sortOrder: "asc",
        });
        setFilteringValue({});
        setActiveMenuValue("partAuctionStart");
      },
      isHidden: false,
      selected: activeMenuValue === "partAuctionStart",
    },
    {
      label: "Nytillagt",
      onClick: () => {
        setFilteringValue({
          queryKey: "publishedTime",
          matchKey: "publishedTime",
          value: "today",
        });
        setActiveMenuValue("partPublishedToday");
      },
      isHidden: !options?.filtering?.auctionsToday,
      selected: activeMenuValue === "partPublishedToday",
    },
  ];

  return <ButtonToggle buttons={buttons} />;
};

export default AuctionButtonToggle;
