const inputFields = {
  group1: [
    {
      key: "userType",
      label: "Kundtyp",
      type: "text",
      validateProps: {},
      canEdit: false,
      valueWrapper: (value: any) => {
        if (value === "privatePerson") {
          return "Privatperson";
        } else if (value === "company") {
          return "Företag";
        }

        return "Ej tilldelad";
      },
    },
    {
      key: "username",
      label: "Användarnamn",
      type: "text",
      required: true,
      validateProps: {},
      canEdit: false,
    },
    {
      key: "infoFirstname",
      label: "Förnamn",
      type: "text",
      required: false,
      validateProps: {},
      canEdit: false,
    },
    {
      key: "infoSurname",
      label: "Efternamn",
      type: "text",
      required: false,
      validateProps: {},
      canEdit: false,
    },
  ],
  group2: [
    {
      key: "infoBoxAddress",
      label: "Boxadress",
      type: "text",
      required: false,
      validateProps: {},
      canEdit: false,
    },
    {
      key: "infoAddress",
      label: "Gatuadress",
      type: "text",
      required: true,
      validateProps: {},
      canEdit: false,
    },
    {
      key: "infoZipCode",
      label: "Postnummer",
      type: "text",
      required: true,
      validateProps: {},
      canEdit: false,
    },
    {
      key: "infoCity",
      label: "Ort",
      type: "text",
      required: true,
      validateProps: {},
      canEdit: false,
    },
    {
      key: "infoCountryCode",
      label: "Land",
      type: "select",
      options: [
        { label: "Sverige", value: "SE" },
        { label: "Norge", value: "NO" },
        { label: "Danmark", value: "DK" },
        { label: "Finland", value: "FI" },
      ],
      required: true,
      validateProps: {},
      canEdit: false,
    },
  ],
  group3: [
    {
      key: "userEmail",
      label: "E-post",
      type: "text",
      required: true,
      validateProps: {},
      canEdit: true,
    },
    {
      key: "infoPhone",
      label: "Telefon",
      type: "text",
      required: true,
      validateProps: {},
      canEdit: true,
    },
    {
      key: "infoCellPhone",
      label: "Mobil",
      type: "text",
      required: false,
      canEdit: false,
      validateProps: {},
    },
  ],
};

export default inputFields;