"use client";

import Input from "@/components/Elements/Input";
import Button from "@/components/Elements/Button";
import { useState } from "react";

export const Search = () => {
  const [searchValue, setSearchValue] = useState("");
  const [hasError, setSetError] = useState(false);

  const searchAuctions = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!searchValue) {
      setSetError(true);
      return;
    }

    window.location.href = `/sok/?s=${searchValue}&t=auctionItems`;
  };

  return (
    <form className="input-group space-y-3" onSubmit={searchAuctions}>
      <Input
        type="text"
        placeholder="Vad letar du efter?"
        icon="search"
        buttonSize="lg"
        iconClass="text-aliceblue-dark"
        fullWidth
        error={hasError}
        onKeyDown={(e) => setSetError(false)}
        onChange={(e) => setSearchValue(e.target.value)}
      />

      <Button size="lg" fullWidth textCenter type="submit">
        {" "}
        Hitta objekt{" "}
      </Button>
    </form>
  );
};
