"use client";

import classNames from "classnames";
import ReactHtmlParser from "html-react-parser";
import { useState } from "react";
import Button from "@/components/Elements/Button";

interface ClientProps extends BlockProps {
  agreementData: any;
}

export const Client: React.FC<ClientProps> = ({
  blockStyle,
  agreementData,
}) => {
  const [activeTermLangId, setActiveTermLangId] = useState<any>(1);

  const agreements = agreementData ? Object.values(agreementData) : [];

  const activeTerm = agreements.find(
    (term: any) => term?.textLangId === activeTermLangId
  ) as any;

  if (!activeTerm) {
    return null;
  }

  return (
    <div className="flex flex-col" style={blockStyle}>
      <div className="flex flex-col md:flex-row space-y-4 justify-between items-center mb-8">
        <span className="flex flex-col">
          <span className="font-bold text-6xl">
            {activeTerm?.titleTextContent}
          </span>
          <span>{activeTerm?.agreementActivated}</span>
        </span>

        <div className="flex justify-center items-center space-x-4">
          <Button
            className={classNames(
              "p-4 py-2 bg-transparent font-semibild justify-center text-md text-blue hover:bg-blue hover:text-white",
              {
                "bg-blue text-white ": activeTermLangId === 1,
              }
            )}
            borderColor="blue"
            onClick={() => {
              setActiveTermLangId(1);
            }}
            iconDirection="left"
          >
            Svenska
          </Button>
          <Button
            className={classNames(
              "p-4 py-2 bg-transparent font-semibild justify-center text-md text-blue hover:bg-blue hover:text-white",
              {
                "bg-blue text-white": activeTermLangId === 2,
              }
            )}
            borderColor="blue"
            onClick={() => {
              setActiveTermLangId(2);
            }}
          >
            English
          </Button>
        </div>
      </div>
      <div className="bg-white p-8 shadow-lg">
        {ReactHtmlParser(activeTerm?.contentTextContent)}
      </div>
    </div>
  );
};
