"use client";

import classNames from "classnames";
import useSWR, { mutate } from "swr";
import { getUserAuctionItems } from "@/actions/auctions";
import { auctionFilterProps, AuctionItem } from "@/types/auction";
import AuctionBlockHeading from "@/components/Auctions/AuctionBlockHeading";
import Button from "@/components/Elements/Button";
import useUserStore from "@/stores/useUserStore";
import { useCallback, useEffect, useState } from "react";
import { useAuctionFilter } from "@/hooks/useAuctionFilter";
import { getRecentItemBidsByItemIds } from "@/actions/bids";
import { EXTRA_BID_TIME, REVALIDATE_BID_TIME } from "@/lib/auctions";
import { MD5 } from "crypto-js";
import AuctionListItems from "@/components/Auctions/AuctionItem/AuctionListItems";
import { getSwedishDate, parseToDate } from "@/lib/utils";

const defaultFilterPropsAuctionItem = {
  pagination: {
    size: 5,
    total: 0,
    page: 1,
  },
  sorting: {
    options: [
      {
        id: "itemEndTimeAsc",
        queryKey: "itemEndTime",
        matchKey: "itemEndTime",
        label: "Kortast tid kvar",
        sortOrder: "asc",
        sortBy: "itemEndTime",
      },
      {
        id: "itemEndTimeDesc",
        queryKey: "itemEndTime",
        matchKey: "itemEndTime",
        label: "Nyast",
        sortOrder: "desc",
        sortBy: "itemEndTime",
      },
      {
        id: "itemSortNoAsc",
        queryKey: "itemSortNo",
        matchKey: "itemSortNo",
        label: "Rop",
        sortOrder: "asc",
        sortBy: "itemSortNo",
      },
    ],
    selections: [
      {
        id: "itemEndTimeDesc",
        matchKey: "itemEndTime",
        queryKey: "itemEndTime",
        sortOrder: "desc",
        sortBy: "itemEndTime",
      },
    ],
  },
  filtering: {
    selections: [],
    options: [],
  },
};

export const Client: React.FC<BlockProps> = ({
  block,
  blockStyle,
  extraClass,
}) => {
  const {
    backgroundColor,
    textColor,
    headingDescription,
    headingTitle,
    headingLink,
    rowsToShow,
  } = block?.attributes;

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, [mounted]);

  const [displayedItems, setDisplayedItems] = useState<any>([]);

  const auctionFilterProps = useAuctionFilter(defaultFilterPropsAuctionItem);
  const { options, setOptions, buildFilterParams } = auctionFilterProps;
  const sortingArgs = options?.sorting as any;
  const [favoriteAuctionItemBids, setFavoriteAuctionItemBids] =
    useState<any>(false);
  const paginationArgs = options?.pagination as any;

  const { user, userId, setFetchKeys, fetchKeys } = useUserStore();

  const userFetchKey =
    fetchKeys?.getUserAuctionItems || MD5(`userBids-${userId}`).toString();

  const fetchKey = user?.id
    ? ["api/getUserAuctionItemFavorites", user.id]
    : null;

  useEffect(() => {
    if (!userId) return;
    mutate(fetchKey);
  }, [userId, userFetchKey]);

  const fetcher = async () => {
    const filter = buildFilterParams();

    const params = {
      offset: (paginationArgs.page - 1) * paginationArgs.size,
      limit: paginationArgs.size,
      sort: {
        [sortingArgs.selections?.[0]?.queryKey]:
          sortingArgs.selections?.[0]?.sortOrder,
      },
      filter: {
        ...filter,
        isFavorited: "yes",
      },
    };

    const { auctionItems: getAllAuctionItems, totalHits: getTotalHits } =
      await getUserAuctionItems(params, userFetchKey);

    // Slice out offset from limit to get the correct items
    const displayedItems =
      getAllAuctionItems?.slice(params.offset, params.offset + params.limit) ||
      [];

    setDisplayedItems(displayedItems);

    const auctionItemIds = displayedItems?.map((item) => item.itemId) || [];

    const { allItemBids, serverTime } = await getRecentItemBidsByItemIds(
      auctionItemIds
    );

    const serverTimeLocal = getSwedishDate(serverTime);

    setFavoriteAuctionItemBids(allItemBids);

    setOptions((prevArgs: auctionFilterProps) => ({
      ...prevArgs,
      pagination: {
        ...prevArgs.pagination,
        total: getTotalHits ? getTotalHits : 0,
      },
    }));

    const allAuctionItems = displayedItems?.map((item: any) => {
      const bidMatch = allItemBids.find(
        (bid: any) => bid.itemId === item.itemId
      );

      const updatedItemEndTime = bidMatch?.itemEndTime || item.itemEndTime;
      const itemEndTimestamp = parseToDate(updatedItemEndTime).getTime();

      return {
        ...item,
        itemEndTime: updatedItemEndTime,
        itemHasEnded:
          itemEndTimestamp + EXTRA_BID_TIME <
          parseToDate(serverTimeLocal).getTime(),
      };
    });

    return allAuctionItems;
  };

  const {
    data: auctionItems,
    error,
    isLoading,
  } = useSWR<AuctionItem[], Error>(fetchKey, fetcher, {
    refreshInterval: REVALIDATE_BID_TIME,
    revalidateOnFocus: true,
  });

  // Fix hydration errors
  if (!mounted || !user) {
    return null;
  }

  const containerClass = classNames(
    "self-stretch md:overflow-hidden flex flex-col items-start justify-start text-left text-base gap-4 mb-8",
    { [`bg-${backgroundColor || "transparent"}`]: true },
    { [`text-${textColor || "black"}`]: true },
    { extraClass: extraClass }
  );

  // const readMoreBtnClass = classNames("text-white group", {
  //   "opacity-0 hidden": (auctionItems?.length || 0) <= totalDisplayedItems,
  // });

  return (
    <div className={containerClass} style={blockStyle}>
      <AuctionBlockHeading
        headingDescription={headingDescription}
        headingTitle={headingTitle}
        headingLink={headingLink}
      />

      <AuctionListItems
        visibleItemsData={displayedItems?.length}
        auctionItemBids={favoriteAuctionItemBids}
        showFilter={false}
        showSorting={false}
        auctionItems={displayedItems}
        filterProps={auctionFilterProps}
        isLoading={isLoading}
        error={error}
        hidePaginationTop
        searchLayout
      />
    </div>
  );
};
