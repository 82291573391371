import LinkWrapper from "@/components/Elements/LinkWrapper";
import { Auction } from "@/types/auction";
import Link from "next/link";
import React from "react";
import ReactHtmlParser from "html-react-parser";
import AuctionCardSlider from "./AuctionCardSlider";
import { getAuctionLink } from "@/lib/links";
import NoImageFound from "@/components/NoImageFound";
import Image from "next/image";
import AuctionEndedImage from "../../AuctionEndedImage";
import moment from "moment";
import "moment/locale/sv";
import { parseToDate } from "@/lib/utils";

moment.updateLocale("sv", {
  relativeTime: {
    future: "%s",
  },
});

interface AuctionCardProps {
  auction: Auction;
}

const getClosingDateDays = (partAuctionStart: string, partStatus: any) => {
  const closingDate = moment(partAuctionStart);
  const currentDate = moment();

  if (!closingDate.isValid()) {
    return "Datum ej specifierat";
  }

  const dateString = closingDate.format("YYYY-MM-DD");

  // Check if the auction has ended
  if (closingDate.isBefore(currentDate, "minute")) {
    return partStatus == "ended" ? dateString : "Avslutas nu";
  }

  // Check if the auction ends tomorrow
  const isTomorrow = closingDate.isSame(
    currentDate.clone().add(1, "day"),
    "day"
  );

  if (isTomorrow) {
    return "imorgon";
  }

  // Calculate relative time difference
  const relativeTime = closingDate.from(currentDate); // e.g., "2 dagar sedan" or "om 2 dagar"

  return relativeTime;
};

const MarkerSvg = () => (
  <svg
    version="1.1"
    id="_x32_"
    xmlns="http://www.w3.org/2000/svg"
    className="h-3 w-3 md:h-4 md:w-4"
    viewBox="0 0 512 512"
  >
    <g>
      <path
        d="M256.016,0C158.797,0.031,80.094,78.781,80.063,175.953c0.063,14.297,3.031,28.641,7.563,43.797
c7.969,26.438,21.094,55.328,36.281,84.547c45.563,87.359,110.328,177.391,110.688,177.891L256.016,512l21.391-29.813
c0.25-0.313,37.969-52.844,76.016-116.266c19.016-31.766,38.141-66.25,52.828-98.859c7.344-16.313,13.578-32.172,18.156-47.313
c4.531-15.156,7.469-29.5,7.531-43.797C431.906,78.781,353.203,0.031,256.016,0z M373.938,204.594
c-6.344,21.156-18.25,47.906-32.594,75.359c-21.484,41.266-48.281,84.375-69.625,116.953c-5.719,8.719-10.969,16.609-15.703,23.594
c-14.891-22-35.594-53.594-55.844-87.75c-17.719-29.906-35.063-61.75-47.656-90.25c-6.297-14.188-11.391-27.547-14.781-39.094
c-3.422-11.5-5-21.281-4.953-27.453c0.016-34.109,13.75-64.734,36.078-87.156c22.391-22.328,53.016-36.063,87.156-36.094
c34.109,0.031,64.75,13.766,87.125,36.094c22.359,22.422,36.078,53.047,36.094,87.156
C379.281,182.344,377.594,192.563,373.938,204.594z"
      />
      <path
        d="M256.016,118.719c-31.594,0-57.219,25.641-57.219,57.234c0,31.609,25.625,57.219,57.219,57.219
c31.578,0,57.219-25.609,57.219-57.219C313.234,144.359,287.594,118.719,256.016,118.719z"
      />
    </g>
  </svg>
);

const AuctionCard: React.FC<AuctionCardProps> = ({ auction }) => {
  const closingDateDays = getClosingDateDays(
    auction.partAuctionStart,
    auction.partStatus
  );

  const cardImages = auction.images
    ? Object.values(auction.images).map((image: any) => {
        return image;
      })
    : [];

  const cardVideos = auction.videos;
  const auctionLink = getAuctionLink(auction);
  const isClosed = auction.partStatus === "ended";
  const hasMedia = cardImages?.length || cardVideos?.length;
  const isNew = auction.publishedToday;

  const formatDate = (date: string) => {
    const d = parseToDate(date);

    if (isNaN(d.getTime())) {
      return "Datum ej specificerat";
    }

    return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(d.getDate()).padStart(2, "0")}`;
  };

  return (
    <div className="flex flex-row items-start justify-start w-full md:w-full">
      <div className="rounded-lg w-full h-full overflow-hidden flex flex-col items-start justify-start border-[1px] border-solid border-gray-light/20">
        <div className="w-full rounded-t-lg bg-black/10 h-[270px] md:h-[352px] overflow-hidden relative">
          {isNew && !isClosed && (
            <div className="absolute top-0 right-0 uppercase text-slate-gray-dark bg-yellow text-xs font-semibold p-3 rounded-bl-lg z-[100]">
              Ny auktion
            </div>
          )}

          <div className="absolute top-0 left-0 uppercase text-black bg-white text-xs font-bold p-3 rounded-br-lg rounded-tl-lg z-[500] -m-[2px]">
            {formatDate(auction.partAuctionStart)}
          </div>

          {hasMedia ? (
            !isClosed ? (
              <AuctionCardSlider
                images={cardImages}
                videos={cardVideos}
                linkTo={auctionLink}
                noZoom
              />
            ) : (
              <Link href={auctionLink}>
                {" "}
                <AuctionEndedImage image={cardImages?.[0] || ""} />
              </Link>
            )
          ) : (
            <Link href={auctionLink}>
              {" "}
              <NoImageFound />{" "}
            </Link>
          )}
        </div>

        <div className="self-stretch bg-white flex flex-col flex-grow items-start justify-between p-6 gap-[24px]">
          <div className="self-stretch flex flex-col items-start justify-start space-y-1 md:space-y-2">
            <div className="flex flex-row items-center justify-start space-x-1 mb-4 md:mb-6 text-base">
              <Image
                className="relative h-4 h-4 md:w-6 md:h-6"
                alt="hourglass"
                src="/icons/hourglass-empty.svg"
                width={24}
                height={24}
              />
              <div className="relative leading-[150%] font-normal text-dark-gray-muted text-base flex items-center shrink-0 text-base">
                {closingDateDays}
              </div>
            </div>
            <Link href={auctionLink} className="!no-underline" shallow>
              <div className="self-stretch relative text-2xl font-semibold text-black font-bold">
                {isClosed && (
                  <span className="font-semibold text-sm p-2 bg-gray-50 mr-1 rounded-lg border border-aliceblue">
                    {auction.auctionId}
                  </span>
                )}{" "}
                {auction.auctionTitle}
              </div>
            </Link>

            <div className="self-stretch relative leading-1 font-normal text-paragraph text-base md:text-lg mt-2 flex flex-row space-x-1 items-center">
              <div className="flex items-center">
                <MarkerSvg />
                <span className="ml-2">{auction.partLocation}</span>
              </div>
            </div>
            <div className="self-stretch relative font-normal ttext-base leading-[150%] text-profile-gray-dark !mt-6">
              {auction?.partTitle && ReactHtmlParser(auction.partTitle)}
            </div>
          </div>
          <LinkWrapper>
            <Link href={auctionLink} className="!no-underline text-blue">
              Se mer om auktionen
            </Link>
          </LinkWrapper>
        </div>
      </div>
    </div>
  );
};

export default AuctionCard;
