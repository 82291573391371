"use client";

import React, { use, useCallback, useEffect, useRef, useState } from "react";
import { FieldValues, set, SubmitHandler, useForm } from "react-hook-form";
import useSWR, { mutate } from "swr";
import ReactHtmlParser from "html-react-parser";
import Button from "@/components/Elements/Button";
import useUserStore from "@/stores/useUserStore";
import Skeleton from "react-loading-skeleton";
import {
  userGetCompanyAliases,
  getUserAlias,
  getUserInfo,
  getUserSettings,
  getUserSettingsAvailable,
  updateUserInfo,
  updateUserSettings,
} from "@/actions/user";
import Row from "./Row";
import toast from "react-hot-toast";
import "react-loading-skeleton/dist/skeleton.css";
import AddCompanyProfiles from "@/components/MyPages/AddCompanyProfiles";
import inputFieldsList from "./inputFields";
import useModalStore from "@/hooks/useModalStore";
import Modal from "@/components/Modal/Modal";
import PopUpSvg from "@/icons/popup.svg";
import { format } from "date-fns";
import { MD5 } from "crypto-js";
import ModalElement from "@/components/Modal/Modal";
import useDebouncedCallback from "@/hooks/useDebouncedCallback";
import { signOut } from "next-auth/react";
import { usePathname } from "next/navigation";
import { useShallow } from "zustand/react/shallow";
import { parseToDate } from "@/lib/utils";

const MyAccount = ({ block, blockStyle, extraClass }: BlockProps) => {
  const [userInfo, setUserInfo] = useState<any>({});
  const [isSubmitting, setisSubmitting] = useState(false);
  const [inputFields, setInputFields] = useState<any>({});
  const { agreementModal } = useModalStore();
  const [isAgreementModalOpen, setIsAgreementModalOpen] = useState(false);
  const { fetchKeys, setFetchKeys, user, userAgreementStatus } = useUserStore(
    useShallow((state) => state)
  );

  const userId = user?.id;

  const userFetchKey =
    fetchKeys?.userInformation || MD5(`userInformation-${userId}`).toString();

  const formRef = useRef<HTMLFormElement>(null);
  const pathname = usePathname();

  useEffect(() => {
    mutate(["api/userGetCompanyAliases", userId]);
    mutate(["api/getUserInfoData", userId, userFetchKey]);
    console.log("pathname changed, mutating");
  }, [pathname]);

  const { data: companyAliases } = useSWR<any, Error>(
    userId ? ["api/userGetCompanyAliases", userId, userFetchKey] : null,
    async () => {
      const conpanyAliases = await userGetCompanyAliases(userFetchKey);
      return conpanyAliases;
    },
    {
      refreshInterval: 0,
      revalidateOnFocus: true,
    }
  );

  useEffect(() => {
    setInputFields(inputFieldsList);

    console.log("Mounted! Setting fetch key for user information");

    setFetchKeys({
      userInformation: MD5(
        `userInformation-${Math.random().toString(36).substring(7)}-${userId}`
      ).toString(),
    });
  }, []);

  const {
    data: userDataResponse,
    error,
    isLoading,
  } = useSWR<any, Error>(
    userId ? ["api/getUserInfoData", userId, userFetchKey] : null,
    async () => {
      const settingsAvailableData = await getUserSettingsAvailable(
        userFetchKey
      );
      const userInfoData = await getUserInfo(userFetchKey);
      const userSettingsData = await getUserSettings(userFetchKey);

      // Temporary only filter out "SET" settings
      const filteredUserSettings = userSettingsData?.filter((setting: any) => {
        const match = settingsAvailableData?.find(
          (availableSetting: any) =>
            availableSetting.settingsKey === setting.settingsKey
        );

        return match?.settingsType === "set";
      });

      return {
        settingsAvailable: settingsAvailableData,
        userInfo: userInfoData,
        userSettings: filteredUserSettings,
      };
    },
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
    }
  );

  const getUserInfoData = userDataResponse?.userInfo;
  const getUserSettingsData = userDataResponse?.userSettings;
  const getUserSettingsAvailableData = userDataResponse?.settingsAvailable;
  const agreementData = userAgreementStatus?.verificationData;
  const userAcceptedAgreementTime = agreementData?.userAcceptedAgreementTime;

  const formatDate = (dateString: string) => {
    const date = parseToDate(dateString);
    return isNaN(date.getTime()) ? "" : `(${format(date, "yyyy-MM-dd")})`;
  };

  const formattedUserAcceptedAgreementTime = formatDate(
    userAcceptedAgreementTime
  );

  const userPin = getUserInfoData?.userPin;

  useEffect(() => {
    mutate(["api/getUserInfoData", userId]);
  }, []);

  const {
    trigger,
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    reset,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: {},
  });

  const userType = watch("userType");
  const isInternational = getUserInfoData?.isInternational;

  let getUserType = userType;

  if (getUserType === "Privatperson") {
    getUserType = "privatePerson";
  } else if (getUserType === "Företag") {
    getUserType = "company";
  }

  useEffect(() => {
    let updatedGroup1 = [...inputFieldsList.group1]; // Clone group1
    if (isInternational === "yes" && getUserType === "company") {
      updatedGroup1.push({
        key: "infoVatNo",
        label: "VAT nummer",
        type: "text",
        required: true,
        validateProps: {},
        canEdit: true,
      });
    } else if (isInternational !== "yes" && getUserType === "company") {
      updatedGroup1.push(
        {
          key: "userPin",
          label: "Org. nummer",
          type: "text",
          required: true,
          validateProps: {},
          canEdit: false,
        },
        {
          key: "infoName",
          label: "Företagsnamn",
          type: "text",
          required: true,
          validateProps: {},
          canEdit: false,
        }
      );
    } else if (isInternational !== "yes" && getUserType === "private") {
      updatedGroup1.push(
        {
          key: "userPin",
          label: "Personnummer",
          type: "text",
          required: true,
          validateProps: {},
          canEdit: false,
        },
        {
          key: "infoName",
          label: "Namn",
          type: "text",
          required: true,
          validateProps: {},
          canEdit: false,
        }
      );
    }

    setInputFields({
      ...inputFieldsList,
      group1: updatedGroup1,
    });
  }, [getUserType, isInternational]);

  useEffect(() => {
    const updatedUserInfo = { ...userInfo };

    if (getUserInfoData) {
      if (!getUserInfoData.notifications) {
        getUserInfoData.notifications = {};
      }

      const flatFieldValues = Object.values(inputFields).flatMap(
        (group) => group
      );

      flatFieldValues.forEach((field: any) => {
        const valueWrapper = field?.valueWrapper;
        const userInfoValue = getUserInfoData[field.key];

        if (userInfoValue && valueWrapper) {
          updatedUserInfo[field.key] = valueWrapper(userInfoValue);
        } else {
          updatedUserInfo[field.key] = userInfoValue;
        }
      });

      setUserInfo((prev: any) => ({ ...prev, ...updatedUserInfo }));
    }
  }, [getUserInfoData, reset, inputFields]);

  // Populate user settings fields
  useEffect(() => {
    if (getUserSettingsData && getUserSettingsAvailableData) {
      const updatedUserInfo = { ...userInfo, userSettings: {} };

      getUserSettingsData.forEach((setting: any) => {
        const settingsValues = setting?.settingsValue?.split(",");

        settingsValues.forEach((key: any) => {
          if (typeof setting.settingsKey === "string") {
            if (!updatedUserInfo.userSettings[setting.settingsKey]) {
              updatedUserInfo.userSettings[setting.settingsKey] = {};
            }

            updatedUserInfo.userSettings[setting.settingsKey][key] = true;
          }
        });
      });

      setUserInfo((prev: any) => ({ ...prev, ...updatedUserInfo }));
    }
  }, [getUserSettingsData, getUserSettingsAvailableData]);

  useEffect(() => {
    reset(userInfo);
  }, [userInfo, reset]);

  const triggerForm = useDebouncedCallback(async () => {
    const isValid = await trigger();

    if (isValid) {
      handleSubmit(onSubmit)();
    } else {
      toast.error(
        "Vi kunde lägga till en medarbetare just nu, försök igen senare.",
        { id: "form-error-toast" }
      );
    }
  }, 2000);

  const validateForm = async (e: any) => {
    e.preventDefault();
    setisSubmitting(true);
    triggerForm();
  };

  const onSubmit: SubmitHandler<FieldValues> = async (
    data,
    e?: React.BaseSyntheticEvent
  ) => {
    e?.preventDefault();

    const allowedSettings = Object.values(inputFields)
      .flatMap((group) => group)
      .filter((field: any) => field.canEdit);

    // Add input field rows to the filtered values
    const userInfoValues: FieldValues = Object.keys(data).reduce((acc, key) => {
      if (allowedSettings.find((field: any) => field.key === key)) {
        acc[key] = data[key];
      }

      return acc;
    }, {} as FieldValues);

    // Extract user settings
    const userSettingsValues = Object.keys(data?.userSettings).reduce(
      (acc: any, key: any) => {
        const settings = data?.userSettings[key];

        acc[key] = Object.keys(settings)
          .filter((settingKey) => settingKey && settings[settingKey])
          .join(",");

        return acc;
      },
      {}
    );

    const hasUserInfoValues = Object.values(userInfoValues)?.length > 0;
    const hasUserSettingsValues = Object.values(userSettingsValues)?.length > 0;

    const updateUserInfoHandle = hasUserInfoValues
      ? updateUserInfo(userInfoValues)
      : Promise.resolve();

    const updateSettingsHandle = hasUserSettingsValues
      ? updateUserSettings(userSettingsValues)
      : Promise.resolve();

    const settingsPromise = Promise.all([
      updateUserInfoHandle,
      updateSettingsHandle,
    ]);

    toast
      .promise(settingsPromise, {
        loading: "Sparar ändringar...",
        success: "Dina uppgifter har sparats.",
        error:
          "Kunde inte uppdatera användaruppgifterna. Försök igen senare eller kontakta Tovek.",
      })
      .finally(() => {
        setisSubmitting(false);
      });
  };

  if (error) {
    return (
      <div className="w-full p-6 bg-white shadow-lg shadow-gray-200 rounded-lg flex flex-col space-y-8 py-12">
        <h2 className="text-lg font-normal text-center text-paragraph">
          Kunde inte hämta användaruppgifterna. Försök igen senare eller pröva
          att{" "}
          <span
            role="button"
            className="font-bold"
            onClick={() => {
              signOut();
            }}
          >
            logga ut
          </span>{" "}
          och in igen.
        </h2>
      </div>
    );
  }

  const BodyContent = (
    <>
      <div className="max-h-[500px] overflow-x-hidden overflow-y-scroll relative bg-gray-200 pb-8 min-h-[500px]">
        {agreementData?.contentTextContent &&
          ReactHtmlParser(agreementData?.contentTextContent)}
        <div className="shadow-scroll-gray sticky bottom-0"></div>
      </div>
      <div className="mt-8 flex flex-col md:flex-row space-y-4 md:space-y-0 justify-center items-center space-x-6">
        <Button
          className="p-4 w-full md:w-auto bg-blue font-semibild text-white justify-center text-md"
          borderColor="blue"
          onClick={() => {
            setIsAgreementModalOpen(false);
          }}
          iconDirection="left"
        >
          Stäng
        </Button>
      </div>
    </>
  );

  return (
    <>
      <ModalElement
        id="agreement-modal"
        sticky
        isOpen={isAgreementModalOpen}
        onClose={agreementModal.onClose}
        modalTitle={agreementData?.titleTextContent}
        modalBody={BodyContent}
        modalTitleClass="!mb-4"
        modalBg="bg-white"
        modalBackDropBackdropClass="fixed inset-0 z-[991] bg-white bg-opacity-30 backdrop-blur-md"
        size={"xl"}
      />

      <form
        ref={formRef}
        onSubmit={validateForm}
        className="flex flex-col w-full"
        style={blockStyle}
      >
        <div className="p-5 sm:p-14 xl:p-20 bg-aliceblue rounded-lg flex flex-col space-y-4 !mb-10">
          <div className="flex flex-col lg:flex-row lg:space-x-9">
            <div className="flex flex-col flex-grow">
              <h3 className="text-xl text-modal-blue my-2 hidden sm:block">
                Grunduppgifter
              </h3>
              <div className="flex flex-col sm:space-y-4">
                {isLoading ? (
                  <Skeleton baseColor="#e8eff5" />
                ) : (
                  <Row
                    fields={inputFields?.group1}
                    title="Grunduppgifter"
                    register={register}
                    errors={errors}
                  />
                )}
              </div>
            </div>

            <div className="flex flex-col flex-grow">
              <h3 className="text-xl text-modal-blue my-2 hidden sm:block">
                Adress
              </h3>
              <div className="flex flex-col sm:space-y-4">
                {isLoading ? (
                  <Skeleton baseColor="#e8eff5" />
                ) : (
                  <Row
                    fields={inputFields?.group2}
                    title="Adress"
                    register={register}
                    errors={errors}
                  />
                )}
              </div>
            </div>

            <div className="flex flex-col flex-grow">
              <h3 className="text-xl text-modal-blue my-2 hidden sm:block">
                Kontaktuppgifter
              </h3>
              <div className="flex flex-col sm:space-y-4">
                {isLoading ? (
                  <Skeleton baseColor="#e8eff5" />
                ) : (
                  <Row
                    fields={inputFields.group3}
                    title="Kontaktuppgifter"
                    register={register}
                    errors={errors}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-col">
            {/*
          <h3 className="text-xl text-modal-blue my-2 mb-4">Inställningar</h3>

          <div className="flex flex-col space-y-5">
            {getUserSettingsAvailableData &&
              getUserSettingsAvailableData?.map(
                (setting: any, index: number) => {
                  const settingsKey = setting?.settingsKey;
                  const settingValues = setting?.settingsValues;

                  if (!settingValues) {
                    return null;
                  }

                  const settingTypes = Object.entries(settingValues);

                  return (
                    <div className="option my-4" key={index}>
                      <div className="font-bold">
                        {setting?.settingsMessage || ""}
                      </div>

                      <div className="option flex align-center space-x-6 py-2">
                        {settingTypes?.map(([key, value]) => {
                          const settingKey = `userSettings.${settingsKey}.${key}`;
                          const isChecked =
                            getFormUserSettings?.[settingsKey]?.[key] ?? false;

                          return (
                            <Checkbox
                              key={key}
                              {...register(settingKey)}
                              onChange={() => handleToggle(settingKey)}
                              ripple={false}
                              checked={isChecked}
                              containerProps={{
                                className: "p-0",
                              }}
                              label={String(value) || ""}
                              icon={<CheckboxIcon />}
                              iconProps={{
                                className: "text-white",
                              }}
                              labelProps={{
                                className: "ml-3",
                              }}
                              className={`h-6 w-6 border-[3px] border-solid text-black border-dark-gray-muted rounded-sm transition-all hover:scale-105 hover:before:opacity-0`}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                }
              )}
          </div>      */}

            {!isLoading && (
              <Button
                className="w-fit"
                bgColor="blue"
                type="submit"
                disabled={isSubmitting}
              >
                Spara ändringar
              </Button>
            )}
          </div>
        </div>
      </form>

      {companyAliases?.length > 0 && (
        <AddCompanyProfiles userPin={userPin} companyUserId={userId} />
      )}

      <h2>Användaravtal</h2>

      <div className="w-full my-4 bg-aliceblue p-6 rounded-lg">
        {userAcceptedAgreementTime ? (
          <div className="flex flex-col space-y-1">
            <span
              className="font-bold text-lg text-toast-blue cursor-pointer flex flex-row space-x-2 items-center"
              onClick={() => setIsAgreementModalOpen(true)}
            >
              {" "}
              <span>{agreementData?.titleTextContent}</span>
              <span className="font-light inlinetext-modal-blue flex flex-row space-x-2">
                (<span>Läs avtal</span>{" "}
                <img src={PopUpSvg?.src} width={16} alt="popup" />)
              </span>
            </span>
            <p className="font-normal text-base flex flex-row space-x-1 items-center">
              <svg
                width="16px"
                height="16px"
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="#3bbe4e"
                  d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm-55.808 536.384-99.52-99.584a38.4 38.4 0 1 0-54.336 54.336l126.72 126.72a38.272 38.272 0 0 0 54.336 0l262.4-262.464a38.4 38.4 0 1 0-54.272-54.336L456.192 600.384z"
                />
              </svg>
              <span className="font-normal text-modal-blue">
                Godkänt{" "}
                <span className="font-normal">
                  {formattedUserAcceptedAgreementTime}
                </span>
              </span>
            </p>
          </div>
        ) : (
          <div className="flex flex-col">
            <span
              className="font-bold text-lg text-toast-blue cursor-pointer flex flex-row space-x-2 items-center"
              onClick={() => setIsAgreementModalOpen(true)}
            >
              {" "}
              <span>{agreementData?.titleTextContent}</span>
              <span className="font-light inlinetext-modal-blue flex flex-row space-x-2">
                (<span>Läs avtal</span>{" "}
                <img src={PopUpSvg?.src} width={16} alt="popup" />)
              </span>
            </span>
            <p className="font-normal text-base">Ej godkänt</p>
          </div>
        )}
      </div>
    </>
  );
};

export default MyAccount;
