"use client";

import { useState } from "react";
import ReactHtmlParser from "html-react-parser";
import classNames from "classnames";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import Image from "next/image";

function Icon(props: any) {
  const { open, setOpen } = props.openProps;

  return (
    <svg
      width="20"
      height="13"
      viewBox="0 0 20 13"
      className={`${open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2.025L1.775 0.25L10 8.475L18.225 0.25L20 2.025L10 12.025L0 2.025Z"
        fill="#2D2D32"
      />
    </svg>
  );
}

interface FAQItemProps {
  attributes: any;
  index: number;
}

const FAQItem: React.FC<FAQItemProps> = ({ attributes, index }) => {
  const { question, answerText, answerImage } = attributes;
  const [open, setOpen] = useState(false);

  const accordionClass = classNames(
    "flex items-center justify-between py-4 border-b bg-white border-none rounded-lg mb-1",
    {
      "rounded-b-none": open,
    }
  );

  return (
    <Accordion
      open={open}
      icon={<Icon id={1} openProps={{ open, setOpen }} />}
      onClick={() => setOpen(!open)}
      className={`relative has-accordion-dots ${open ? "open" : "closed"}`}
    >
      <AccordionHeader className={accordionClass}>
        <span className="text-xl font-semibold flex flex-row gap-6 items-center">
          <div className="h-[40px] w-[40px] rounded-full border-2 border-blue flex items-center justify-center text-16">
            {index}
          </div>
          <span>{question && ReactHtmlParser(question)}</span>
        </span>
      </AccordionHeader>
      <AccordionBody className="bg-white p-5 px-16 flex flex-col space-y-8 relative">
        {open && (
          <div className="absolute flex-1 pl-4 overflow-hidden h-full left-0 ml-[13px] before:absolute before:bg-circle-pattern before:bg-repeat-y before:bg-[length:8px_32px] before:top-0 before:left-0 before:w-2.5 before:h-full before:content-['']"></div>
        )}
        {answerImage && (
          <Image
            src={answerImage}
            width={260}
            height={136}
            className="flex items-center justify-center"
            alt="Answer image"
          />
        )}
        <span>{answerText && ReactHtmlParser(answerText)}</span>
      </AccordionBody>
    </Accordion>
  );
};

export default FAQItem;
