"use client";

import { use, useEffect, useState } from "react";
import ReactHtmlParser from "html-react-parser";
import classNames from "classnames";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import Image from "next/image";

function Icon(props: any) {
  const { open, setOpen } = props.openProps;

  return (
    <svg
      width="20"
      height="13"
      viewBox="0 0 20 13"
      className={`${
        open ? "rotate-180" : ""
      } h-2 w-3 md:h-5 md:w-5 transition-transform`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2.025L1.775 0.25L10 8.475L18.225 0.25L20 2.025L10 12.025L0 2.025Z"
        fill="#2D2D32"
      />
    </svg>
  );
}

interface FAQItemProps {
  attributes: any;
  bodyClass?: string;
  icon?: string;
  openOnInit?: boolean;
}

const FAQItem: React.FC<FAQItemProps> = ({
  attributes,
  bodyClass,
  icon,
  openOnInit,
}) => {
  const { question, answer } = attributes;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (openOnInit) {
      setOpen(true);
    }
  }, [openOnInit]);

  const accordionClass = classNames(
    "flex items-center justify-start py-4 border-b bg-white border-none rounded-lg group",
    {
      "rounded-b-none": open,
    }
  );

  const accordionBodyClass = classNames("bg-white p-5 text-16", {
    [`${bodyClass}`]: bodyClass,
  });

  return (
    <Accordion open={open} icon={<Icon id={1} openProps={{ open, setOpen }} />}>
      <AccordionHeader
        className={accordionClass}
        onClick={() => setOpen(!open)}
      >
        <span className="order-1 text-lg md:text-xl font-normal px-5 flex justify-start items-center w-full">
          {question && ReactHtmlParser(question)}
          {icon && (
            <span className="ml-auto">
              <Image
                src={icon}
                width={24}
                height={24}
                alt="FAQ Icon"
                className="opacity-50 group-hover:opacity-100"
              />
            </span>
          )}
        </span>
      </AccordionHeader>
      <AccordionBody className={accordionBodyClass}>
        {answer && ReactHtmlParser(answer)}
      </AccordionBody>
    </Accordion>
  );
};

export default FAQItem;
