"use client";

import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import classNames from "classnames";
import Link from "next/link";
import useMediaQuery from "@/hooks/useMediaQuery";

interface ButtonProps {
  buttons: ButtonState[];
  wrapperClass?: string;
  useLinks?: boolean;
  useMobileVersion?: boolean;
}

interface ButtonState {
  selected?: boolean;
  label: string;
  onClick?: () => void;
  useLink?: boolean;
  href?: string;
  slug?: string;
  isBold?: boolean;
  isSearch?: boolean;
  isHidden?: boolean;
}

const ButtonToggle: React.FC<ButtonProps> = ({
  buttons,
  wrapperClass = "",
  useMobileVersion = true,
  useLinks = false,
}) => {
  const isMobile = useMediaQuery("(max-width: 640px)");
  const [buttonState, setButtonState] = useState<ButtonState[] | null>(null);

  const memoizedButtons = useMemo(() => buttons, [buttons]);

  useLayoutEffect(() => {
    setButtonState(memoizedButtons);
  }, [memoizedButtons]);

  const handleClickState = (button: ButtonState, id: number) => {
    setButtonState((prev: any) =>
      prev.map((item: any, index: number) => ({
        ...item,
        selected: index === id,
      }))
    );

    if (button.onClick) {
      button.onClick();
    }
  };

  const containerClass = classNames("rounded-lg bg-white", {
    [wrapperClass]: wrapperClass,
  });

  const innerClass = classNames(
    "flex flex-row items-center justify-start p-2 gap-[12px] fit-content md:w-auto"
  );

  if (!buttonState?.length) return null;

  return (
    <div className={containerClass}>
      {useMobileVersion && isMobile ? (
        <>
          <select
            className="w-full rounded-lg border-gray-light text-gray-dark"
            onChange={(e) => {
              const selectedIndex = e.target.value;
              const selectedButton = buttonState[parseInt(selectedIndex, 10)];
              if (selectedButton) {
                handleClickState(selectedButton, parseInt(selectedIndex, 10));
              }
            }}
            value={buttonState.findIndex((btn) => btn.selected)}
          >
            {buttonState.map((button, index) => (
              <option
                key={button.slug || index}
                value={index}
                className={classNames({
                  "!hidden": button?.isHidden,
                })}
              >
                {button.label}
              </option>
            ))}
          </select>
        </>
      ) : (
        // Render buttons for larger viewports
        <div className={innerClass}>
          {buttonState.map((button, index) => (
            <button
              key={index}
              className={classNames(
                "rounded-lg flex flex-grow justify-center whitespace-nowrap flex-row items-center hover:bg-aliceblue/50 py-[9px] px-[18px] gap-[9px] !no-underline",
                {
                  "bg-aliceblue cursor-default hover:bg-aliceblue":
                    button.selected,
                  "!hidden": button?.isHidden,
                }
              )}
              onClick={() => handleClickState(button, index)}
            >
              <div
                className={classNames(
                  "relative tracking-[-0.01em] leading-[150%] ",
                  {
                    "text-gray-600": button?.isSearch,
                  },
                  {
                    "!font-bold !text-black":
                      button?.isBold || button?.selected,
                  }
                )}
              >
                {button.label}
              </div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default ButtonToggle;
