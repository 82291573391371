"use client";

import { getUserFinancing } from "@/actions/user";
import TableAccordion from "@/components/Elements/TableAccordion";
import useUserStore from "@/stores/useUserStore";
import React from "react";
import useSWR from "swr";

const Finance = ({ block, blockStyle, extraClass }: BlockProps) => {
  const { user } = useUserStore();

  const userId = user?.id;

  const fetchKey = ["api/getUserFinances", userId];

  const {
    data: userFinances,
    error,
    isLoading,
  } = useSWR<Finance[], Error>(
    fetchKey,
    async () => {
      const getFinancing = await getUserFinancing();
      // const params = {
      //   offset: paginationArgs.page * paginationArgs.size,
      //   limit: paginationArgs.size,
      //   sort: { [filtering.sorting.sortBy]: filtering.sorting.sortOrder },
      // };
      // const fetchedData = await getAuctionItems(auctionID, params);
      // if (fetchedData?.totalHits) {
      //   setPaginationArgs((prevArgs: any) => ({
      //     ...prevArgs,
      //     total: fetchedData?.totalHits,
      //   }));
      // }
      //return fetchedData.auctionItems;

      return getFinancing;
    },
    {
      refreshInterval: 30000, // 300000 // 5 minutes in milliseconds
    }
  );

  const items = [
    {
      call: "Rop 4 kontorsstolar",
      entryDate: "Dec 12, 2022",
      expiryDate: "Jan 12, 2023, 13:05",
      total: "3000 kr",
      status: "Förfrågan skickad",
    },
    {
      call: "Rop 2 laptops",
      entryDate: "Dec 15, 2022",
      expiryDate: "Jan 15, 2023, 14:30",
      total: "5000 kr",
      status: "Förfrågan skickad",
    },
    {
      call: "Rop 10 skrivbord",
      entryDate: "Dec 18, 2022",
      expiryDate: "Jan 18, 2023, 12:00",
      total: "8000 kr",
      status: "Förfrågan skickad",
    },
    {
      call: "Rop 6 stolar",
      entryDate: "Dec 20, 2022",
      expiryDate: "Jan 20, 2023, 16:45",
      total: "4000 kr",
      status: "Förfrågan skickad",
    },
  ];

  const columns = [
    {
      name: "Rop",
      accessor: "call",
    },
    {
      name: "Datum inskickat",
      accessor: "entryDate",
    },
    {
      name: "Annons utgår",
      accessor: "expiryDate",
    },
    {
      name: "Total",
      accessor: "total",
    },
    {
      name: "Status",
      accessor: "status",
    },
  ];

  // This is not used as of now

  return (
    <div className="flex flex-col space-y-6 w-full" style={blockStyle}>
      <h3 className="text-5xl mb-6">Finansering</h3>

      <TableAccordion columns={columns} items={items} />
    </div>
  );
};

export default Finance;
