import { useEffect, useRef } from "react";

const Iframe = ({ content }: { content: string }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const iframe = iframeRef.current;
    let cleanupFn: (() => void) | undefined;

    if (iframe) {
      const iframeDoc =
        iframe.contentDocument || iframe.contentWindow?.document;
      if (iframeDoc) {
        iframeDoc.open();
        iframeDoc.write(content);
        iframeDoc.close();
      }
    }
  }, [content]);

  return (
    <iframe
      ref={iframeRef}
      className="scrollbar-hide"
      style={{ width: "100%", height: "100%" }}
    />
  );
};

export default Iframe;
