"use client";

import { useState } from "react";
import classNames from "classnames";
import Image from "next/image";

interface PersonProps {
  attributes: any;
  parentAttributes: any;
}

const Person: React.FC<PersonProps> = ({ attributes, parentAttributes }) => {
  const { email, name, telephone, role, image: ImageUrl } = attributes;

  const { textColor } = parentAttributes;

  return (
    <div className="flex-col justify-start items-center gap-3 inline-flex">
      <div className="h-[100px] md:mx-auto md:max-h-auto aspect-square bg-transparent">
        <Image
          src={ImageUrl || "/images/no-avatar.png"}
          alt={name}
          className="h-full w-full object-cover rounded-full"
          width="400"
          height="400"
        />
      </div>
      {name && (
        <div
          className={`self-stretch text-center ${
            textColor ? "text-" + textColor : "text-black"
          } text-lg md:text-xl font-semibold leading-loose`}
        >
          {name}
        </div>
      )}
      {role && (
        <div
          className={`self-stretch text-center ${
            textColor ? "text-" + textColor : "text-profile-gray-dark"
          } text-base font-normal leading-normal`}
        >
          {role}
        </div>
      )}
      {telephone && (
        <div className="self-stretch text-center text-base font-normal leading-normal">
          <a
            href={`tel:${telephone}`}
            className="!no-underline text-blue flex justify-center items-center"
          >
            <span className="hidden md:flex flex-row items-center space-x-2">
              {/* <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                className="opacity-45"
              >
                <path d="M20.01 15.38C18.78 15.38 17.59 15.18 16.48 14.82C16.13 14.7 15.74 14.79 15.47 15.06L13.9 17.03C11.07 15.68 8.42 13.13 7.01 10.2L8.96 8.54C9.23 8.26 9.31 7.87 9.2 7.52C8.83 6.41 8.64 5.22 8.64 3.99C8.64 3.45 8.19 3 7.65 3H4.19C3.65 3 3 3.24 3 3.99C3 13.28 10.73 21 20.01 21C20.72 21 21 20.37 21 19.82V16.37C21 15.83 20.55 15.38 20.01 15.38Z" />
              </svg> */}
              <span>{telephone}</span>
            </span>
            <span className="block md:hidden mt-2">
              <svg
                width="42"
                height="42"
                viewBox="0 0 42 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="42" height="42" rx="8" fill="#E8EFF5" />
                <mask
                  id="mask0_848_11882"
                  maskUnits="userSpaceOnUse"
                  x="9"
                  y="9"
                  width="24"
                  height="24"
                >
                  <rect x="9" y="9" width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_848_11882)">
                  <path
                    d="M28.95 30C26.8 30 24.7042 29.5208 22.6625 28.5625C20.6208 27.6042 18.8125 26.3375 17.2375 24.7625C15.6625 23.1875 14.3958 21.3792 13.4375 19.3375C12.4792 17.2958 12 15.2 12 13.05C12 12.75 12.1 12.5 12.3 12.3C12.5 12.1 12.75 12 13.05 12H17.1C17.3333 12 17.5417 12.075 17.725 12.225C17.9083 12.375 18.0167 12.5667 18.05 12.8L18.7 16.3C18.7333 16.5333 18.7292 16.7458 18.6875 16.9375C18.6458 17.1292 18.55 17.3 18.4 17.45L15.975 19.9C16.675 21.1 17.5542 22.225 18.6125 23.275C19.6708 24.325 20.8333 25.2333 22.1 26L24.45 23.65C24.6 23.5 24.7958 23.3875 25.0375 23.3125C25.2792 23.2375 25.5167 23.2167 25.75 23.25L29.2 23.95C29.4333 24 29.625 24.1125 29.775 24.2875C29.925 24.4625 30 24.6667 30 24.9V28.95C30 29.25 29.9 29.5 29.7 29.7C29.5 29.9 29.25 30 28.95 30ZM15.025 18L16.675 16.35L16.25 14H14.025C14.1083 14.6833 14.225 15.3583 14.375 16.025C14.525 16.6917 14.7417 17.35 15.025 18ZM23.975 26.95C24.625 27.2333 25.2875 27.4583 25.9625 27.625C26.6375 27.7917 27.3167 27.9 28 27.95V25.75L25.65 25.275L23.975 26.95Z"
                    fill="#0A5599"
                  />
                </g>
              </svg>
            </span>
          </a>
        </div>
      )}
      {email && (
        <div className="self-stretch text-center text-base font-normal leading-normal">
          <a
            href={`mailto:${email}`}
            className="!no-underline text-blue flex justify-center items-center"
          >
            {/* <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              className="opacity-45"
            >
              <path d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 8L12 13L4 8V6L12 11L20 6V8Z" />
            </svg> */}
            <span className="hidden md:flex flex-row items-center space-x-2">
              <span>{email}</span>{" "}
            </span>
            <span className="block md:hidden mt-2">
              <svg
                width="42"
                height="42"
                viewBox="0 0 42 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="42" height="42" rx="8" fill="#E8EFF5" />
                <mask
                  id="mask0_848_11799"
                  maskUnits="userSpaceOnUse"
                  x="9"
                  y="9"
                  width="24"
                  height="24"
                >
                  <rect x="9" y="9" width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_848_11799)">
                  <path
                    d="M13 29C12.45 29 11.9792 28.8042 11.5875 28.4125C11.1958 28.0208 11 27.55 11 27V15C11 14.45 11.1958 13.9792 11.5875 13.5875C11.9792 13.1958 12.45 13 13 13H29C29.55 13 30.0208 13.1958 30.4125 13.5875C30.8042 13.9792 31 14.45 31 15V27C31 27.55 30.8042 28.0208 30.4125 28.4125C30.0208 28.8042 29.55 29 29 29H13ZM21 22L13 17V27H29V17L21 22ZM21 20L29 15H13L21 20ZM13 17V15V27V17Z"
                    fill="#0A5599"
                  />
                </g>
              </svg>
            </span>
          </a>
        </div>
      )}
    </div>
  );
};

export default Person;
