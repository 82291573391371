import Button from "@/components/Elements/Button";
import Input from "@/components/Elements/Input";
import classNames from "classnames";
import Image from "next/image";
import { useContext, useEffect, useRef, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { AddProfileContext } from ".";
import {
  addCompanyUserAlias,
  removeCompanyUserAlias,
  updateCompanyUserAlias,
} from "@/actions/user";
import { getErrorCodeMessage } from "@/lib/reporting";
import { mutate } from "swr";
import useUserStore from "@/stores/useUserStore";
import { MD5 } from "crypto-js";
const ProfileRow: React.FC<any> = ({ profile }) => {
  const { isAddingProfile, setIsAddingProfile, companyUserId } =
    useContext<any>(AddProfileContext);
  const { setFetchKeys } = useUserStore();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);

  const isNewEdit = !profile && !isEditing;

  const mutateOptions = () => {
    setFetchKeys({
      userInformation: MD5(
        `userInformation-${Math.random()
          .toString(36)
          .substring(7)}-${companyUserId}`
      ).toString(),
    });

    mutate(["api/getCompanyAlias", companyUserId]);
  };

  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm<FieldValues>();

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    setIsSubmitting(true);

    const infoPin = data.infoPin.replace(/\D/g, "");
    const infoName = data.infoName;

    if (isNewEdit) {
      const errorMessage =
        "Vi kunde inte lägga till medarbetaren. Försök igen senare eller kontakta oss om problemet kvarstår.";
      addCompanyUserAlias(infoName, infoPin, companyUserId)
        .then((data) => {
          if (data?.result !== "success") {
            toast.error(
              getErrorCodeMessage(data?.error?.code, null, errorMessage)
            );
            return;
          }

          toast.success("En medarbetare har lagts till!");
          mutateOptions();
          setIsAddingProfile(false);
        })
        .catch((error) => {
          toast.error(errorMessage);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else if (profile) {
      const errorMessage =
        "Vi kunde inte uppdatera medarbetaren. Försök igen senare eller kontakta oss om problemet kvarstår.";

      let postData: { newName?: string; newPin?: string } = {};

      if (infoName) {
        postData.newName = infoName;
      }

      if (infoPin) {
        postData.newPin = infoPin;
      }

      updateCompanyUserAlias(profile?.pin, companyUserId, postData)
        .then((data) => {
          if (data?.result !== "success") {
            toast.error(
              getErrorCodeMessage(data?.error?.code, null, errorMessage)
            );
            setIsSubmitting(false);
            return;
          }

          mutateOptions();
          setIsEditing(false);
        })
        .catch(() => {
          toast.error(errorMessage, {
            id: "updateCompanyUserAlias",
          });
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else {
      toast.error(
        "Vi kan inte uppdatera medarbetaren just nu. Försök igen senare.",
        {
          id: "updateCompanyUserAlias",
        }
      );
      setIsSubmitting(false);
    }
  };

  const inputClassName = classNames(
    `text-black md:text-xl m-0 !py-0 leading-1`,
    { "opacity-50 pointer-events-none": isSubmitting }
  );

  const inputWrapperClass = classNames(
    "flex flex-col bg-white rounded-md py-2 space-y-1 border-l-4 border-blue border-solid",
    {
      "opacity-50 pointer-events-none !border-none":
        isSubmitting || (profile && !isEditing),
    }
  );

  const triggerFormSubmit = () => {
    handleSubmit(onSubmit)();
  };

  const onRemoveAlias = async () => {
    const errorMessage =
      "Vi kunde inte ta bort medarbetaren. Försök igen senare eller kontakta oss om problemet kvarstår.";
    const isConfirmed = confirm(
      "Är du säker på att du vill ta bort denna medarbetare?"
    );

    if (!isConfirmed) {
      return;
    }

    try {
      setIsSubmitting(true);

      const response = await removeCompanyUserAlias(
        profile?.pin,
        companyUserId
      );

      if (response?.result === "success") {
        toast.success("Medarbetaren har tagits bort!");

        mutateOptions();
      } else {
        toast.error(
          getErrorCodeMessage(response?.error?.code, null, errorMessage)
        );
      }
    } catch (error) {
      toast.error(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col w-full"
    >
      <div className="grid grid-cols-1 md:grid-cols-12 space-y-4 md:space-y-0 md:space-x-4">
        <div className="flex flex-col relative col-span-5">
          <div
            key="infoName"
            className={classNames(inputWrapperClass, {
              "!border-danger": !!errors?.infoName,
            })}
          >
            <label
              htmlFor="infoName"
              className="uppercase font-light tracking-widtext-14 mt-2 px-4"
            >
              Namn
            </label>
            <Input
              id="infoName"
              type="text"
              placeholder="Namn"
              inputClassName={inputClassName}
              defaultValue={profile?.aliasName || ""}
              onKeyUp={() => {
                clearErrors("infoName");
              }}
              {...register("infoName", {
                required: true,
              })}
            />
          </div>
          {!!errors?.infoName && (
            <span className="py-2 text-danger absolute top-full left-0">
              {errors?.infoName?.message as string}
            </span>
          )}
        </div>
        <div className="flex flex-col relative col-span-5">
          <div
            key="infoPin"
            className={classNames(inputWrapperClass, {
              "!border-danger": !!errors?.infoPin,
            })}
          >
            <label
              htmlFor="infoPin"
              className="uppercase font-light tracking-wide text-14 mt-2 px-4"
            >
              Personnummer
            </label>

            <Input
              id="infoPin"
              type="text"
              placeholder="ÅÅÅÅMMDDXXXX"
              inputClassName={inputClassName}
              defaultValue={profile?.pin || ""}
              onKeyUp={() => {
                clearErrors("infoPin");
              }}
              onInput={(e) => {
                e.currentTarget.value = e.currentTarget.value.replace(
                  /[^0-9]/g,
                  ""
                );
              }}
              {...register("infoPin", {
                required: true,
                validate: (value) => {
                  const regex =
                    /^(19|20)?\d{2}((0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01]))\d{4}$/;

                  if (value.length != 12) {
                    return "Personnummer måste vara 12 tecken långt.";
                  }

                  return regex.test(value) || "Ogiltigt personnummer.";
                },
              })}
            />
          </div>
          {!!errors?.infoPin && (
            <span className="py-2 text-danger absolute top-full left-0">
              {errors?.infoPin?.message as string}
            </span>
          )}
        </div>
        {profile ? (
          <div className="flex flex-row space-x-4 items-center col-span-2 pl-2 whitespace-nowrap">
            <div
              className="flex flex-row text-14 font-semibold text-blue space-x-2 items-center cursor-pointer"
              onClick={() => setIsEditing((prev: boolean) => !prev)}
            >
              <Image src="/icons/pen.svg" width={18} height={18} alt="remove" />
              <span>{!isEditing ? "Ändra" : "Avbryt"}</span>
            </div>

            <div
              className="flex flex-row text-14 font-semibold text-blue space-x-2 items-center cursor-pointer"
              onClick={onRemoveAlias}
            >
              <Image
                src="/icons/trash.svg"
                width={18}
                height={18}
                alt="remove"
              />
              <span>Ta bort</span>
            </div>
          </div>
        ) : (
          <div className="flex flex-row space-x-4 items-center col-span-2 pl-2 whitespace-nowrap">
            <div
              className="flex flex-row text-14 font-semibold text-blue space-x-2 items-center cursor-pointer"
              onClick={() => setIsAddingProfile(false)}
            >
              <Image src="/icons/pen.svg" width={18} height={18} alt="remove" />
              <span>Avbryt</span>
            </div>
          </div>
        )}
      </div>
      {(isEditing || isNewEdit) && (
        <div className="flex flex-row items-center space-x-2 my-4">
          <Button
            className="w-full w-fit"
            bgColor="blue"
            borderColor="transparent"
            onClick={() => triggerFormSubmit()}
            disabled={isSubmitting}
          >
            Spara ändringar
          </Button>
          {isNewEdit && (
            <Button
              className="w-full w-fit"
              bgColor="transparent"
              borderColor="blue"
              textColor="blue"
              type="reset"
              onClick={() => reset()}
              disabled={isSubmitting}
            >
              Återställ
            </Button>
          )}
        </div>
      )}
    </form>
  );
};

export default ProfileRow;
