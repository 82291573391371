"use client";

import Button from "@/components/Elements/Button";
import TableAccordion from "@/components/Elements/TableAccordion";
import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import useSWR, { mutate } from "swr";
import useUserStore from "@/stores/useUserStore";
import {
  getUserInvoicePdf,
  getUserInvoices,
  payInvoices,
} from "@/actions/user";
import Skeleton from "react-loading-skeleton";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { base64StringToBlob } from "blob-util";
import toast from "react-hot-toast";
import Modal from "@/components/Modal/Modal";
import Iframe from "@/components/Iframe";
import NotFoundText from "@/components/NotFoundText";
import { getErrorCodeMessage } from "@/lib/reporting";
import { useShallow } from "zustand/react/shallow";
import CustomPagination from "../../../components/CustomPagination";
import useCustomPagination from "@/hooks/useCustomPagination";
import { formatPrice, parseToDate } from "@/lib/utils";
import ModalElement from "@/components/Modal/Modal";
import "react-loading-skeleton/dist/skeleton.css";
import { MD5 } from "crypto-js";

const Invoices = ({ block, blockStyle, extraClass }: BlockProps) => {
  const { user, setFetchKeys, fetchKeys } = useUserStore(
    useShallow((state) => state)
  );

  const userId = user?.userId;

  const [paginationArgsPaid, setPaginationArgsPaid] = useState<any>({
    page: 1,
    size: 10,
    total: 0,
  });

  const [paginationArgsUnpaid, setPaginationArgsUnpaid] = useState<any>({
    page: 1,
    size: 10,
    total: 0,
  });

  const [displayedPaidItems, setDisplayedPaidItems] = useState<any>([]);
  const [displayedUnpaidItems, setDisplayedUnpaidItems] = useState<any>([]);

  const [payModal, setPayModal] = useState({
    isOpen: false,
    content: "",
  });

  const invoiceFetchKey =
    fetchKeys?.userInvoices || MD5(`userInvoices-${userId}`).toString();

  const modalIsOpen = payModal?.isOpen;

  const [selectedRowsUnpaid, setSelectedRowsUnpaid] = useState([]);
  const [selectedRowsPaid, setSelectedRowsPaid] = useState([]);

  const numSelectedRowsPaid = selectedRowsPaid.length;
  const numSelectedRowsUnpaid = selectedRowsUnpaid.length;

  const totalValueMarkedRows = selectedRowsUnpaid.reduce(
    (acc: number, row: any) => {
      const amount = row?.values?.invoiceLeftAmout
        ? row?.values?.invoiceLeftAmout
        : row?.values?.invoiceLeftAmount || 0;

      return acc + amount;
    },
    0
  );

  const userInvoicesFetchKey = ["api/getUserInvoices", userId, invoiceFetchKey];

  const { data: userInvoices, isLoading } = useSWR<Invoice[], Error>(
    userInvoicesFetchKey,
    async () => {
      const getInvoices = await getUserInvoices(invoiceFetchKey);

      const paid = getInvoices?.filter(
        (invoice) => invoice.invoiceStatus === "paid"
      );

      const unpaid = getInvoices?.filter(
        (invoice) =>
          invoice.invoiceStatus === "unpaid" ||
          invoice.invoiceStatus === "partpaid"
      );

      setPaginationArgsPaid((prev: any) => ({
        ...prev,
        total: paid?.length,
      }));

      setPaginationArgsUnpaid((prev: any) => ({
        ...prev,
        total: unpaid?.length,
      }));

      return getInvoices;
    },
    {
      refreshInterval: 10000,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  );

  const getInvoiceStatusText = (invoice: any) => {
    const status = invoice?.invoiceStatus;
    const amount = invoice?.invoiceLeftAmount;

    if (["unpaid", "partpaid"].includes(status) && amount < 0) {
      return "Outnyttjad";
    }

    if (status == "paid" && amount < 0) {
      return "Utnyttjad";
    }

    switch (status) {
      case "unpaid":
        return "Obetald";
      case "paid":
        return "Betald";
      case "partpaid":
        return "Delbetald";
      case "pending":
        return "Väntande";
      default:
        return "Ohanterad";
    }
  };

  const mapInvoiceNames = (invoice: Invoice) => {
    const date = parseToDate(invoice.invoiceDate);

    const formattedDate = new Intl.DateTimeFormat("sv-SE", {
      year: "numeric",
      month: "long",
      day: "2-digit",
    }).format(date);

    const isPaid = invoice.invoiceStatus === "paid";
    const totalLeft = isPaid
      ? invoice?.invoiceTotalAmount || 0
      : invoice?.invoiceLeftAmount || 0;

    return {
      invoiceId: invoice.invoiceId,
      invoiceNo: invoice.invoiceNo,
      invoiceDate: formattedDate,
      invoiceLeftAmountFormatted: `${formatPrice(totalLeft)}`,
      invoiceLeftAmount: totalLeft,
      invoiceStatus: invoice.invoiceStatus,
      invoiceStatusText: getInvoiceStatusText(invoice),
    };
  };

  const paidItems = useMemo(
    () =>
      userInvoices
        ?.filter((invoice) => invoice.invoiceStatus === "paid")
        .map(mapInvoiceNames) || [],
    [userInvoices]
  );

  const unpaidItems = useMemo(() => {
    return (
      userInvoices
        ?.filter(
          (invoice) =>
            invoice.invoiceStatus === "unpaid" ||
            invoice.invoiceStatus === "partpaid"
        )
        .map((invoice) => ({
          ...mapInvoiceNames(invoice),
          action: (
            <div className="flex flex-row space-x-4">
              <div
                className="text-black  text-sm border px-3 py-2 rounded-lg border-gray-300 cursor-pointer hover:bg-blue hover:text-white w-fit px-0"
                onClick={() => downloadInvoice(invoice)}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 15V18H6V15H4V18C4 19.1 4.9 20 6 20H18C19.1 20 20 19.1 20 18V15H18ZM17 11L15.59 9.59L13 12.17V4H11V12.17L8.41 9.59L7 11L12 16L17 11Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
              {/* <div
                className="text-black  text-sm px-3 py-2 rounded-lg border border-gray-300 cursor-pointer  hover:bg-blue hover:text-white w-fit"
                onClick={() => payInvoices([invoice?.invoiceId])}
              >
                <svg
                  height="24"
                  width="24"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 502.685 502.685"
                >
                  <g>
                    <g>
                      <path
                        fill="currentColor"
                        d="M482.797,276.924c4.53-5.824,6.73-13.331,4.724-20.988L428.05,30.521
			c-3.451-13.029-16.847-20.837-29.854-17.386L18.184,113.331C5.22,116.761-2.61,130.2,0.798,143.207L60.269,368.6
			c3.408,13.007,16.868,20.816,29.876,17.408l134.278-35.419v75.476c0,42.214,69.954,64.303,139.11,64.303
			c69.113,0,139.153-22.089,139.153-64.302V311.61C502.685,297.869,495.157,286.307,482.797,276.924z M439.763,199.226l6.212,23.469
			l-75.541,19.953l-6.169-23.512L439.763,199.226z M395.931,50.733l11.799,44.695l-118.014,31.148l-11.799-44.695L395.931,50.733z
			 M342.975,224.744l6.04,22.951c-27.934,1.251-55.113,6.126-76.943,14.452l-4.616-17.429L342.975,224.744z M79.984,319.224
			l-6.169-23.426l75.519-19.975l6.212,23.555L79.984,319.224z M170.625,270.237l75.476-19.953l5.716,21.506
			c-1.834,1.122-3.559,2.286-5.242,3.473l-69.781,18.421L170.625,270.237z M477.491,424.209c0,24.612-50.993,44.544-113.958,44.544
			c-62.9,0-113.937-19.953-113.937-44.544v-27.718c0-0.928,0.539-1.769,0.69-2.653c3.602,23.34,52.654,41.847,113.247,41.847
			c60.614,0,109.687-18.508,113.268-41.847c0.151,0.884,0.69,1.726,0.69,2.653V424.209z M477.491,369.678
			c0,24.591-50.993,44.522-113.958,44.522c-62.9,0-113.937-19.931-113.937-44.522V341.96c0-0.906,0.539-1.769,0.69-2.653
			c3.602,23.318,52.654,41.869,113.247,41.869c60.614,0,109.687-18.551,113.268-41.869c0.151,0.884,0.69,1.747,0.69,2.653V369.678z
			 M363.532,356.11c-62.9,0-113.937-19.931-113.937-44.501c0-24.569,51.036-44.5,113.937-44.5c62.965,0,113.958,19.931,113.958,44.5
			C477.491,336.179,426.497,356.11,363.532,356.11z"
                      />
                    </g>
                  </g>
                </svg>
              </div>{" "} */}
            </div>
          ),
        })) || []
    );
  }, [userInvoices]);

  useCustomPagination({
    paginationArg: paginationArgsPaid,
    allItems: paidItems || [],
    setItems: setDisplayedPaidItems,
  });

  useCustomPagination({
    paginationArg: paginationArgsUnpaid,
    allItems: unpaidItems || [],
    setItems: setDisplayedUnpaidItems,
  });

  useEffect(() => {
    mutate(userInvoicesFetchKey);
  }, [modalIsOpen]);

  const downloadInvoice = async (invoice: any) => {
    const invoiceId = invoice?.invoiceId;
    const invoiceNo = invoice?.invoiceNo;

    const ret = await getUserInvoicePdf(Number(invoiceId));

    if (ret) {
      try {
        const pdfBlob = base64StringToBlob(ret, "application/pdf");
        saveAs(pdfBlob, `Faktura_${invoiceNo}.pdf`);
      } catch (error) {
        toast.error(
          `Misslyckades med att ladda ner PDF för faktura ${invoiceNo}`
        );
      }
    } else {
      toast.error(`Misslyckades med att hämta PDF för faktura ${invoiceNo}`);
    }
  };

  const downloadInvoices = async (rows: any) => {
    const zip = new JSZip();
    let errors = [] as any;

    const downloadInvoicesPromise = async (rows: any, zip: JSZip) => {
      for (let row of rows) {
        const invoiceNo = row?.values?.invoiceNo;

        const getInvoice =
          userInvoices && userInvoices.find
            ? userInvoices.find((invoice) => invoice.invoiceNo === invoiceNo)
            : null;

        if (!getInvoice) {
          toast.error(`Kunde inte hitta faktura ${invoiceNo}`);
          return;
        }

        const invoiceId = getInvoice.invoiceId;
        const ret = await getUserInvoicePdf(Number(invoiceId));

        if (ret) {
          const pdfBlob = base64StringToBlob(ret, "application/pdf");
          zip.file(`Faktura_${invoiceNo}.pdf`, pdfBlob);
        } else {
          errors.push(invoiceNo);
        }
      }

      if (errors.length) {
        throw new Error(`Misslyckades med att ladda ner fakturor`);
      }

      const genZip = await zip.generateAsync({ type: "blob" });

      saveAs(genZip, "fakturor.zip");
    };

    toast.promise(downloadInvoicesPromise(rows, zip), {
      loading: "Laddar ner fakturor...",
      success: "Fakturor nerladdade!",
      error: (err) => `Misslyckades med att ladda ner fakturor`,
    });
  };

  const columns = [
    {
      name: "Faktura",
      accessor: "invoiceNo",
    },
    {
      name: "Datum",
      accessor: "invoiceDate",
    },
    {
      name: "Total",
      accessor: "invoiceLeftAmountFormatted",
    },
    {
      name: "Status",
      accessor: "invoiceStatusText",
    },
    {
      name: "Åtgärd",
      accessor: "action",
    },
  ];

  const payIvoicesHandler = async () => {
    if (!selectedRowsUnpaid || !userInvoices) return;

    const invoiceIds = selectedRowsUnpaid.reduce((acc: number[], row: any) => {
      const invoiceNo = row?.values?.invoiceNo;

      const getInvoice = userInvoices.find
        ? userInvoices.find((invoice) => invoice.invoiceNo === invoiceNo)
        : null;

      if (!getInvoice) {
        return acc;
      }

      return [...acc, getInvoice?.invoiceId];
    }, []);

    try {
      const response = await payInvoices(invoiceIds);
      const embed = response?.data?.embed;

      if (!embed) {
        toast.error(
          getErrorCodeMessage(
            response?.error?.code,
            null,
            "Misslyckades med att hämta betalningsformulär, försök igen senare. (1)"
          )
        );
        return;
      }

      setPayModal({
        isOpen: true,
        content: embed,
      });
    } catch (error) {
      toast.error(
        "Misslyckades med att hämta betalningsformulär, försök igen senare. (2)"
      );
    }
  };

  const closePayModal = () => {
    setFetchKeys({
      userInvoices: MD5(
        `userInvoices-${Math.random().toString(36).substring(7)}-${userId}`
      ).toString(),
    });

    setSelectedRowsPaid([]);
    setSelectedRowsUnpaid([]);

    setPayModal({
      isOpen: false,
      content: "",
    });
  };

  // Memoized content
  const memoizedContent = useMemo(() => payModal?.content, [payModal?.content]);

  // Memoized Modal
  const memoizedModal = useMemo(() => {
    return (
      <ModalElement
        isOpen={payModal?.isOpen}
        size="xl"
        modalBg="aliceblue"
        wrapperExtraClass="!p-0 scrollbar-hide !shadow-none"
        useFloatingHeader
        modalBody={
          <div className="h-[800px] lg:h-[750px] p-6 md:p-6 md:py-12 bg-white rounded-lg">
            <Iframe content={memoizedContent} />
          </div>
        }
        onClose={closePayModal}
      />
    );
  }, [payModal?.isOpen, memoizedContent]);

  return (
    <>
      {memoizedModal}

      <div className="flex flex-col space-y-6 w-full" style={blockStyle}>
        <div className="flex flex-col sm:space-y-4">
          <div className="flex flex-col">
            <div className="flex flex-col sm:flex-row justify-between md:items-center my-6 space-y-6 sm:space-y-0">
              <h3 className="text-lg md:text-5xl">Obetalda fakturor</h3>

              {!!numSelectedRowsUnpaid && (
                <div className="flex flex-row space-x-4">
                  <Button
                    className={classNames(
                      "text-blue border-blue hover:bg-blue hover:text-white w-fit !opacity-0 pointer-events-none",
                      {
                        "!opacity-100 pointer-events-auto":
                          numSelectedRowsUnpaid,
                      }
                    )}
                    bgColor="transparent"
                    icon={"download"}
                    onClick={() => downloadInvoices(selectedRowsUnpaid)}
                  >
                    Ladda ner markerade{" "}
                    {numSelectedRowsUnpaid ? `(${numSelectedRowsUnpaid})` : ""}
                  </Button>

                  <Button
                    className={classNames(
                      "text-blue border-blue hover:bg-blue hover:text-white pointer-events-none border-2 !border-solid w-fit !opacity-0",
                      {
                        "!opacity-100 pointer-events-auto hover:!bg-blue hover:!text-white":
                          numSelectedRowsUnpaid && totalValueMarkedRows > 0,
                      }
                    )}
                    bgColor="transparent"
                    onClick={payIvoicesHandler}
                  >
                    Betala{" "}
                    {numSelectedRowsUnpaid ? `(${numSelectedRowsUnpaid})` : ""}
                  </Button>
                </div>
              )}
            </div>

            {isLoading ? (
              <Skeleton />
            ) : !displayedUnpaidItems?.length ? (
              <NotFoundText
                title="Inga fakturor"
                description="Det finns inga obetalda fakturor att lista."
              />
            ) : (
              <>
                <TableAccordion
                  columns={columns}
                  items={displayedUnpaidItems}
                  useCheckbox
                  setSelectedRows={setSelectedRowsUnpaid}
                />

                <CustomPagination
                  paginationArgs={paginationArgsUnpaid}
                  setPaginationArgs={paginationArgsUnpaid}
                />
              </>
            )}
          </div>

          <div className="flex flex-col">
            <div className="flex flex-col md:flex-row justify-between md:items-center my-6 space-y-6 sm:space-y-0">
              <h3 className="text-lg md:text-5xl">Betalda fakturor</h3>
              {!!numSelectedRowsPaid && (
                <Button
                  className={classNames(
                    "text-blue border-blue hover:bg-blue hover:text-white w-fit !opacity-0 pointer-events-none",
                    {
                      "!opacity-100 pointer-events-auto": numSelectedRowsPaid,
                    }
                  )}
                  bgColor="transparent"
                  icon="download"
                  onClick={() => downloadInvoices(selectedRowsPaid)}
                >
                  Ladda ner markerade{" "}
                  {numSelectedRowsPaid ? `(${numSelectedRowsPaid})` : ""}
                </Button>
              )}
            </div>

            {isLoading ? (
              <Skeleton />
            ) : !displayedPaidItems?.length ? (
              <NotFoundText
                title="Inga fakturor"
                description="Det finns inga betalda fakturor att lista."
              />
            ) : (
              <>
                <TableAccordion
                  columns={columns}
                  items={displayedPaidItems}
                  setSelectedRows={setSelectedRowsPaid}
                  useCheckbox
                  showRowForSingleDownload
                />

                <CustomPagination
                  paginationArgs={paginationArgsPaid}
                  setPaginationArgs={setPaginationArgsPaid}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Invoices;
