"use client";

import { useCallback, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import classNames from "classnames";

import "swiper/css";
import "swiper/css/pagination";
import Image from "next/image";

const breakPointDesktop = 1000;

const Icon: React.FC<{ direction: string }> = ({ direction }) => {
  const iconClass = classNames({
    "rotate-0": direction === "prev",
    "rotate-180": direction === "next",
  });

  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={iconClass}
    >
      <rect
        width="40"
        height="40"
        rx="8"
        transform="matrix(-1 0 0 1 40 0.5)"
        fill="white"
      />
      <mask
        id="mask0_947_7488"
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="24"
        height="25"
      >
        <rect
          width="24"
          height="24"
          transform="matrix(-1 0 0 1 32 8.5)"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_947_7488)">
        <path
          d="M22.6 26.5L24 25.1L19.4 20.5L24 15.9L22.6 14.5L16.6 20.5L22.6 26.5Z"
          fill="#2D2D32"
        />
      </g>
    </svg>
  );
};

export const Client: React.FC<BlockProps> = ({
  block,
  blockStyle,
  extraClass,
}) => {
  const [swiperIsLoaded, setSwiperIsLoaded] = useState(false);
  const sliderRef = useRef<any>(null);

  const { slides } = block?.attributes;

  const containerClass = classNames(
    "swiper-slider mb-12 md:mb-0 md:px-16 relative visibility-hidden",
    { extraClass: extraClass },
    { "visibility-visible": swiperIsLoaded }
  );
  const swiperContainerClass = "md:h-[319px]";
  const swiperWrapperClass = "mb-16 md:mb-0";
  const swiperImageClass =
    "h-full w-full rounded-lg aspect-square object-cover";

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const swiperLoaded = () => {
    if (!sliderRef.current) return;
    setSwiperIsLoaded(true);
  };

  return (
    <>
      <style>
        {`:root {
          --swiper-pagination-color: var(--swiper-theme-color);
          --swiper-pagination-bullet-size: 12px;
          --swiper-pagination-bullet-width: 12px;
          --swiper-pagination-bullet-height: 12px;
          --swiper-pagination-bullet-horizontal-gap: 4px;
          --swiper-pagination-bullet-vertical-gap: 6px;
        }
        
        @media (min-width: ${breakPointDesktop}px) {
          .swiper-pagination {
            display: none !important;
          }
        }
        `}
      </style>
      <div className={containerClass} style={blockStyle}>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          navigation={false}
          onSwiper={swiperLoaded}
          pagination={{
            dynamicBullets: true,
            enabled: true,
          }}
          ref={sliderRef}
          className={swiperContainerClass}
          wrapperClass={swiperWrapperClass}
          modules={[Pagination]}
          breakpoints={{
            [breakPointDesktop]: {
              slidesPerView: 3,
              // Not working, why?
              pagination: {
                enabled: false,
              },
            },
          }}
        >
          {slides &&
            slides.map((slide: any, index: any) => (
              <SwiperSlide key={index}>
                <Image
                  src={slide.url}
                  alt={slide.alt}
                  className={swiperImageClass}
                  width={400}
                  height={400}
                />
              </SwiperSlide>
            ))}
        </Swiper>
        <div
          className="prev-arrow absolute z-10 -bottom-[30px] md:bottom-auto md:top-[50%] left-[51px] md:left-0 -translate-y-1/2 cursor-pointer hover:-translate-x-[5px] duration-300 transition"
          onClick={handlePrev}
        >
          <Icon direction="prev" />
        </div>
        <div
          className="next-arrow absolute z-10 -bottom-[30px] md:bottom-auto md:top-[50%] right-[51px] md:right-0 -translate-y-1/2 cursor-pointer hover:translate-x-[5px] duration-300 transition"
          onClick={handleNext}
        >
          <Icon direction="next" />
        </div>
      </div>
    </>
  );
};
