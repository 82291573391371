"use client";

import classNames from "classnames";
import useSWR, { mutate } from "swr";
import { getAuctions } from "@/actions/auctions";
import { AuctionList } from "@/components/Auctions/Auction/AuctionList";
import AuctionBlockHeading from "@/components/Auctions/AuctionBlockHeading";
import { useEffect, useMemo, useRef, useState } from "react";
import Button from "@/components/Elements/Button";
import Link from "next/link";
import { useSearchParams } from "next/navigation";
import { useAuctionFilter } from "@/hooks/useAuctionFilter";
import qs from "query-string";
import { REVALIDATE_AUCTION_TIME } from "@/lib/auctions";

interface ClientProps extends BlockProps {
  defaultFilterSettingProps?: any;
  filteringSettings: any;
  partStatus: string;
}

export const Client: React.FC<ClientProps> = ({
  block,
  blockStyle,
  extraClass,
  filteringSettings,
  partStatus = "running",
}) => {
  const {
    backgroundColor,
    textColor,
    layoutType,
    autoScrollOnChange,
    headingDescription,
    headingTitle,
    headingLink,
    rowsToShow,
    showPagination,
    showReadMore,
    readMoreButton,
  } = block?.attributes;

  const [filteredAuctions, setFilteredAuctions] = useState<any>([]);

  const blockPagination = showPagination !== undefined ? showPagination : true;

  const sorting = {
    options: [
      {
        matchKey: "partAuctionStart",
        queryKey: "partAuctionStart",
        label: "Slutar snart",
        sortOrder: "asc",
        sortBy: "partAuctionStart",
      },
    ],
    selections: [
      {
        matchKey: "partAuctionStart",
        queryKey: "partAuctionStart",
        sortBy: "partAuctionStart",
        sortOrder: partStatus == "ended" ? "desc" : "asc",
      },
    ],
  };

  const auctionFilterProps = useAuctionFilter({
    filtering: {
      options: filteringSettings,
      selection: [],
    },
    sorting: sorting,
    pagination: {
      size: rowsToShow ? rowsToShow : partStatus === "running" ? 15 : 50,
      page: 1,
    },
  });

  const { options, setOptions, buildFilterParams, buildSortingParams } =
    auctionFilterProps;

  const paginationArgs = options?.pagination as any;

  const auctionScrollRef = useRef<HTMLDivElement>(null);
  const params = useSearchParams();

  let currentQuery: any = params ? qs.parse(params.toString()) : {};
  const page = currentQuery?.sida ? Number(currentQuery.sida) || 1 : 1;

  const filteringSelections = options?.filtering?.selections;

  useEffect(() => {
    if (!autoScrollOnChange) return;

    if (window.innerWidth > 768) {
      return;
    }

    window.scrollTo(0, 0);
  }, [paginationArgs?.page]);

  // Dont fetch on first load, this is merely to avoid the loading animation
  const optionsJson = JSON.stringify(options);

  const conditionalFetchKey = useMemo(() => {
    return ["api/getAuctions", optionsJson, paginationArgs.page];
  }, [optionsJson, paginationArgs.page, page]);

  // Reset pagination page to 1 when filter changes
  useEffect(() => {
    setOptions((prevOptions: any) => ({
      ...prevOptions,
      filtering: {
        ...prevOptions.filtering,
      },
      pagination: {
        ...prevOptions.pagination,
        page: 1,
      },
    }));
  }, [filteringSelections]);

  const auctionFetcher = async () => {
    const filter = buildFilterParams();
    const sorting = buildSortingParams();

    const params = {
      offset: (paginationArgs.page - 1) * paginationArgs.size,
      limit: paginationArgs.size,
      sort: sorting,
      filter: filter,
      partStatus: partStatus,
    };

    const {
      auctions: getAllAuctions,
      totalHits: getTotalHits,
      auctionsToday,
    } = await getAuctions(params);

    setOptions((prevOptions: any) => ({
      ...prevOptions,
      filtering: {
        ...prevOptions.filtering,
        auctionsToday: auctionsToday,
      },
      pagination: {
        ...prevOptions.pagination,
        total: getTotalHits ? getTotalHits : 0,
      },
    }));

    if (getAllAuctions && getAllAuctions?.length) {
      setFilteredAuctions(getAllAuctions);
    }

    return getAllAuctions;
  };

  // Scroll to top
  useEffect(() => {
    if (paginationArgs.hasPaginated && auctionScrollRef?.current) {
      auctionScrollRef?.current?.scrollIntoView({ behavior: "smooth" });

      setOptions((prevOptions: any) => ({
        ...prevOptions,
        pagination: {
          ...prevOptions.pagination,
          hasPaginated: false,
        },
      }));
    }
  }, [paginationArgs.hasPaginated]);

  const {
    data: allAuctionsFromFetcher,
    error,
    isLoading,
  } = useSWR<any, Error>(conditionalFetchKey, auctionFetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: true,
    refreshInterval: REVALIDATE_AUCTION_TIME,
  });

  const containerClass = classNames(
    "self-stretch md:overflow-hidden flex flex-col items-start justify-start text-left text-base gap-4",
    { [`bg-${backgroundColor || "transparent"}`]: true },
    { [`text-${textColor || "black"}`]: true },
    { extraClass: extraClass }
  );

  const auctionsFetched =
    allAuctionsFromFetcher && allAuctionsFromFetcher?.length
      ? allAuctionsFromFetcher
      : filteredAuctions;

  return (
    <div className={containerClass} style={blockStyle} ref={auctionScrollRef}>
      {layoutType === "ongoing" ? (
        <AuctionBlockHeading
          headingDescription={headingDescription}
          headingTitle={headingTitle}
          headingLink={headingLink}
        />
      ) : null}

      <AuctionList
        auctions={auctionsFetched}
        isLoading={isLoading}
        error={error}
        layoutType={layoutType}
        filterProps={auctionFilterProps}
        showPagination={blockPagination}
        readMoreButton={readMoreButton}
      />

      {showReadMore &&
        readMoreButton &&
        !isLoading &&
        filteredAuctions &&
        !!filteredAuctions.length && (
          <div className="flex justify-center w-full my-4">
            <Link href={readMoreButton.url} className="!no-underline">
              <Button color="white" bgColor="blue" icon="arrow-right">
                {readMoreButton.text}
              </Button>
            </Link>
          </div>
        )}
    </div>
  );
};
