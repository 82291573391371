"use client";

import React from "react";
import classNames from "classnames";
import Image from "next/image";

interface ButtonProps {
  children: any;
  onClick?: () => void;
  extraClass?: string;
}

const LinkWrapper: React.FC<ButtonProps> = ({ children, extraClass = "" }) => {
  const _linkClass = classNames(
    "flex flex-row items-center justify-start gap-[8px] text-right font-semibold text-base text-blue group child-a:!no-underline cursor-pointer [&>a]:!no-underline",
    { [extraClass]: !!extraClass }
  );

  return (
    <div className={_linkClass}>
      {children}
      <Image
        className="relative w-3 h-3 transition-all duration-500 group-hover:translate-x-[4px]"
        alt="Right arrow icon"
        src="/icons/right-arrow.svg"
        width={16}
        height={16}
      />
    </div>
  );
};

export default LinkWrapper;
