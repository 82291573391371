"use client";

import React from "react";
import {
  Checkbox as MTCheckbox,
  CheckboxProps as MTCheckboxProps,
} from "@material-tailwind/react";

interface CheckboxProps extends MTCheckboxProps {}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ ...checkboxProps }, ref) => {
    return (
      <MTCheckbox
        ripple={false}
        {...(checkboxProps as any)}
        color="indigo"
        ref={ref}
        containerProps={{
          className: "p-0 text-white bg-white",
        }}
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="h-5 w-5"
            stroke="currentColor"
            strokeWidth="1"
          >
            <path
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              fillRule="evenodd"
              clipRule="evenodd"
            ></path>
          </svg>
        }
        iconProps={{
          className: "text-white",
        }}
        className="h-6 w-6 bg-transparent border-[2px] border-solid border-blue rounded-md transition-all hover:scale-105 hover:before:opacity-0"
      />
    );
  }
);

Checkbox.displayName = "Checkbox";

export default Checkbox;
