import React from "react";
import NotificationPagination from "../../../components/CustomPagination";
import BidNotification from "./NotificationTypes/BidNotification";
import NotFoundText from "@/components/NotFoundText";
import { NotificationContext } from "./NotificationContext";
import "react-loading-skeleton/dist/skeleton.css";

const UserNotifications: React.FC<any> = ({ notifications }) => {
  const { paginationArgs, setPaginationArgs } =
    React.useContext(NotificationContext);

  const total = paginationArgs?.total || 0;
  const hasItems = notifications && notifications?.length > 0;

  return (
    <div className="flex flex-col space-y-4">
      {(total <= 0 || !notifications?.length) && !notifications?.length && (
        <>
          <NotFoundText
            title="Inga notiser"
            description="Inga notiser hittades med de valda filtren."
          />
        </>
      )}
      {!!hasItems &&
        notifications &&
        notifications?.map((notification: any, index: number) => (
          <BidNotification key={index} notification={notification} />
        ))}
      {!!hasItems && (
        <NotificationPagination
          paginationArgs={paginationArgs}
          setPaginationArgs={setPaginationArgs}
        />
      )}
    </div>
  );
};

export default React.memo(UserNotifications);
