"use client";

import classNames from "classnames";
import React, { useCallback, useEffect } from "react";
import { auctionFilterProps, useAuctionFilterProps } from "@/types/auction";

interface AuctionBarActionsPanelMyPagesProps {
  filterProps: useAuctionFilterProps;
}

const AuctionBarActionsPanelMyPages: React.FC<
  AuctionBarActionsPanelMyPagesProps
> = ({ filterProps }) => {
  const { options, setOptions } = filterProps;

  const updateFilter = (matchKey: any, value: any) => {
    setOptions((prev: auctionFilterProps) => ({
      ...prev,
      filtering: {
        ...prev.filtering,
        selections: [
          {
            matchKey: matchKey,
            queryKey: matchKey,
            value: value,
          },
        ],
      },
    }));
  };

  const filterSelections = options?.filtering?.selections as any;
  const filterOptions = options?.filtering?.options;

  return (
    <div className="w-full flex-col justify-start items-start gap-6 inline-flex">
      <div className="w-full justify-start items-start gap-6 inline-flex">
        <div className="justify-start items-start gap-7 flex">
          <div className="flex-col justify-start items-start gap-3 inline-flex">
            <div className="self-stretch text-zinc-800 text-base font-normal leading-normal">
              Visa:
            </div>
            <div className="justify-start items-start gap-4 inline-flex flex-wrap">
              {filterOptions?.map((filter: any, index: number) => {
                const isSelected =
                  filterSelections?.[0]?.matchKey == filter.matchKey;

                return (
                  <div
                    className={classNames(
                      "p-2 px-5 bg-aliceblue font-semibold rounded-lg text-14 items-center justify-start space-x-3 flex cursor-pointer",
                      {
                        "bg-blue font-semibold text-white": isSelected,
                        "bg-aliceblue border-aliceblue-dark": !isSelected,
                      }
                    )}
                    onClick={() => updateFilter(filter.matchKey, filter.value)}
                    key={index}
                  >
                    <span>{filter.label}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuctionBarActionsPanelMyPages;
