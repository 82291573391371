import { dismissNotificationById } from "@/actions/user";
import NoImageFound from "@/components/NoImageFound";
import { getAuctionItemLink } from "@/lib/links";
import classNames from "classnames";
import moment from "moment";
import Link from "next/link";
import React from "react";
import { Tooltip } from "react-tooltip";
import { mutate } from "swr";
import "moment/locale/sv";
import { formatPrice } from "@/lib/utils";

interface BidNotificationProps {
  notification: any;
}

const BidNotification: React.FC<BidNotificationProps> = ({ notification }) => {
  //const [isDismissingRef.current, setIsDismissing] = React.useState(false);
  const { data, meta, created, action } = notification;

  const auctionItem = data?.auctionItem;

  // Use ref to stick between renders
  const isDismissingRef = React.useRef<any>(false);

  const maybeDismissNotification = async () => {
    if (notification.read || isDismissingRef.current) return;

    isDismissingRef.current = true;

    try {
      const dismsiss = await dismissNotificationById(notification.id);

      if (dismsiss !== "OK") {
        throw new Error("Error dismissing notification");
      }

      mutate("user/getUserNotifications");
    } catch (error) {
      //toast.error("Kunde inte avfärda notifikationen. försök igen senare.");
      isDismissingRef.current = false;
      console.error("Error dismissing notification", error);
    } finally {
      isDismissingRef.current = false;
    }
  };

  const images =
    auctionItem?.images && Object.values(auctionItem?.images as any);
  const image = images?.length ? images[0] : ("" as any);
  const url = auctionItem ? getAuctionItemLink(auctionItem) : false;

  // Later on we can add more action types here
  // action = outbid etc.

  const actionTextDescriptions = {
    overbid_auto: "Du har blivit överbjuden genom autobud på",
    overbid_normal: "Du har blivit överbjuden på",
  } as any;

  const notificationClass = classNames(
    "bg-white w-full py-4 px-4 relative z-10",
    {
      "cursor-pointer": !notification.read,
      "pointer-events-none opacity-50": isDismissingRef.current,
      "opacity-50": notification.read,
    }
  );

  return (
    <div className={notificationClass} onClick={maybeDismissNotification}>
      {!notification.read && !isDismissingRef.current && (
        <span
          className="absolute min-w-[12px] min-h-[12px] rounded-full py-1 px-1 text-xs font-medium content-[''] leading-none grid place-items-center top-[4%] right-0 translate-x-2/4 -translate-y-2/4 bg-yellow text-white"
          data-tooltip-id="notification-tooltip"
          data-tooltip-content="Markera som läst"
        ></span>
      )}
      <div className="flex items-center">
        <div className="flex-shrink-0 pt-0.5">
          {image ? (
            <img className="h-10 w-10 rounded-full" src={image} alt="" />
          ) : (
            <div className="w-[50px] h-[50px]">
              <NoImageFound text="" containerClass="!min-h-full" useIcon />
            </div>
          )}
        </div>

        <div className="ml-3 flex-1">
          {auctionItem ? (
            <p className="text-md font-medium text-gray-900">
              {actionTextDescriptions[notification?.action] ||
                "Du har blivit överbjuden på"}{" "}
              <Link
                href={url || ""}
                className="!no-underline text-blue"
                target="_blank"
              >
                <strong>{auctionItem.itemTitle}</strong>
              </Link>{" "}
              {meta?.leadingBid && (
                <>
                  nytt ledande bud är{" "}
                  <strong>{formatPrice(meta?.leadingBid)}</strong>
                </>
              )}
              {created && (
                <div className="text-sm text-gray-500">
                  {moment(created).fromNow()}
                </div>
              )}
            </p>
          ) : (
            <p className="text-md font-medium text-gray-900">
              Auktionen du har blivit överbjuden på har tagits bort.
            </p>
          )}
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default BidNotification;
