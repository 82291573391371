"use client";

import useIsMobile from "@/hooks/useIsMobile";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import React from "react";

interface ClientProps {
  innerBlocksHTML: any;
  accordionTitle: string;
}

function Icon(props: any) {
  const { open, setOpen } = props.openProps;

  return (
    <svg
      width="20"
      height="13"
      viewBox="0 0 20 13"
      className={`${open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 2.025L1.775 0.25L10 8.475L18.225 0.25L20 2.025L10 12.025L0 2.025Z"
        fill="#2D2D32"
      />
    </svg>
  );
}

export const Client: React.FC<ClientProps> = ({
  innerBlocksHTML,
  accordionTitle,
}) => {
  const isMobile = useIsMobile();
  const [open, setOpen] = React.useState(0);

  const handleOpen = (value: any) => setOpen(open === value ? 0 : value);

  return isMobile ? (
    <Accordion
      open={open === 1}
      icon={<Icon id={1} openProps={{ open, setOpen }} />}
    >
      <AccordionHeader onClick={() => handleOpen(1)} className="py-0 border-0">
        {accordionTitle}
      </AccordionHeader>
      <AccordionBody className="!space-y-8">{innerBlocksHTML}</AccordionBody>
    </Accordion>
  ) : (
    <>{innerBlocksHTML}</>
  );
};
