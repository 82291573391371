"use client";

import { createFreightRequest } from "@/actions/user";
import Button from "@/components/Elements/Button";
import toast from "react-hot-toast";
import Checkbox from "@/components/Elements/Checkbox";
import "react-loading-skeleton/dist/skeleton.css";
import Radio from "@/components/Elements/Radio";
import { useState, useEffect } from "react";
import { mutate } from "swr";
import { parseToDate } from "@/lib/utils";

interface FreightRequestProps {
  closeModal: () => void;
  invoices: any;
  userId: number;
  addresses: any;
}

const getInvoiceStatusText = (invoice: any) => {
  const status = invoice?.invoiceStatus;
  const amount = invoice?.invoiceTotalAmount;

  if (["unpaid", "partpaid"].includes(status) && amount < 0) {
    return "Outnyttjad";
  }

  if (status == "paid" && amount < 0) {
    return "Utnyttjad";
  }

  switch (status) {
    case "unpaid":
      return "Obetald";
    case "paid":
      return "Betald";
    case "partpaid":
      return "Delbetald";
    case "pending":
      return "Väntande";
    default:
      return "Ohanterad";
  }
};

const formatLastInvoiceDate = (lastDate: string): string => {
  if (!lastDate) return "N/A";

  const date = parseToDate(lastDate);

  if (isNaN(date.getTime())) {
    return "N/A";
  }

  return date.toLocaleDateString("sv-SE");
};

export default function FreightRequest({
  closeModal,
  addresses,
  invoices,
  userId,
}: FreightRequestProps) {
  const [selectedAddressId, setSelectedAddressId] = useState<number | null>(
    null
  );
  const [selectedInvoices, setSelectedInvoices] = useState<number[]>([]);
  const [requestMessage, setRequestMessage] = useState<string>("");

  // Auto-select
  useEffect(() => {
    if (addresses.length === 1) {
      setSelectedAddressId(addresses[0].addressId); // Auto-select the only address
    }

    setSelectedInvoices(invoices.map((invoice: any) => invoice.invoiceId));
  }, [addresses]);

  const toggleInvoiceSelection = (invoiceId: number) => {
    setSelectedInvoices(
      (prev: any) =>
        prev.includes(invoiceId)
          ? prev.filter((id: any) => id !== invoiceId) // Remove if already selected
          : [...prev, invoiceId] // Add if not selected
    );
  };

  const requestAction = async () => {
    if (!selectedAddressId) {
      toast.error("Välj en mottagaradress innan du skickar förfrågan.");
      return;
    }
    if (selectedInvoices.length === 0) {
      toast.error("Välj minst en faktura innan du skickar förfrågan.");
      return;
    }

    try {
      const response = await createFreightRequest({
        invoiceIds: selectedInvoices.map((id) => id),
        requestMessage: requestMessage || "",
        requestReceiverAddressId: selectedAddressId,
      });

      if (!response?.id) {
        throw new Error(`#${selectedInvoices}`);
      }

      const results = [{ invoiceId: selectedInvoices, success: true }];

      // Check if any requests failed
      const failedRequests = results.filter((res: any) => !res.success);

      if (failedRequests.length === 0) {
        toast.success("Alla fraktförfrågningar har skickats.", {
          id: "freightSuccess",
        });
      } else {
        toast.error(
          `Kunde inte skicka in (${
            failedRequests.length
          }) fakturor: ${failedRequests
            .map((res: any) => `#${res.invoiceId}`)
            .join(", ")}`,
          { id: "freightError" }
        );
      }
    } catch (error: any) {
      toast.error(`En del fakturor kunde inte skapas: ${error.message}`);
    } finally {
      mutate(["api/getUserFreightData", userId]);
      closeModal();
    }
  };

  if (!addresses?.length) {
    toast.error("Ett fel uppstod vid hämtning av fraktdata.", {
      id: "freightError",
    });
    closeModal();
    return null;
  }

  return (
    <div className="w-full max-w-3xl mx-auto">
      <div className="space-y-8 mt-4">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-100">
              <th className="text-xs text-muted-gray text-left font-semibold p-4"></th>
              <th className="text-xs text-muted-gray text-left font-semibold p-4">
                Faktura
              </th>
              <th className="text-xs text-muted-gray text-left font-semibold p-4">
                Be om fraktförslag senast
              </th>
              <th className="text-xs text-muted-gray text-left font-semibold p-4">
                Betald
              </th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice: any, index: number) => (
              <tr
                key={invoice.id || `invoice-${index}`}
                className="bg-white text-sm leading-1"
              >
                {/* Checkbox */}
                <td className="p-3 flex">
                  <Checkbox
                    ripple={false}
                    onChange={() => toggleInvoiceSelection(invoice.invoiceId)}
                    checked={selectedInvoices.includes(invoice.invoiceId)}
                  />
                </td>

                {/* Invoice Number */}
                <td className="p-3">{`Faktura #${invoice?.invoiceNo}`}</td>

                {/* Invoice Information */}
                <td className="p-3">
                  {formatLastInvoiceDate(
                    invoice?.addressFreightRequestLastDate
                  )}
                </td>

                {/* Payment Status */}
                <td className="p-3">{getInvoiceStatusText(invoice)}</td>

                <td></td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="space-y-6 text-slate-gray-dark">
          <div>
            <h3 className="text-sm font-normal uppercase mb-2">
              Mottagaradress
            </h3>
            {addresses.map((address: any, index: number) => (
              <div key={index} className="flex items-center space-x-2">
                <Radio
                  name="address"
                  checked={selectedAddressId === address.addressId} // Controlled state
                  labelProps={{ className: "!text-black !text-base" }}
                  label={`${address?.addressName}, ${
                    address?.addressContactPerson
                      ? ` (${address?.addressContactPerson})`
                      : ""
                  }, ${address?.addressAddress}, ${address?.addressCity}`}
                  className="space-y-2"
                  onChange={() => setSelectedAddressId(address.addressId)}
                />
                <div className="flex items-center space-x-2">
                  {address.address2}
                </div>
                <div className="flex items-center space-x-2">
                  {address.address3}
                </div>
                <div className="flex items-center space-x-2">
                  {address.address4}
                </div>
              </div>
            ))}
          </div>

          <div className="text-slate-gray-dark">
            <h3 className="text-sm uppercase mb-2 font-normal">Meddelande</h3>
            <textarea
              className="w-full h-24 p-2 border border-slate-gray-light rounded min-h-[200px]"
              placeholder="Skriv ett meddelande"
              value={requestMessage}
              onChange={(e) => setRequestMessage(e.target.value)}
            ></textarea>
          </div>
        </div>

        <div className="flex justify-end gap-4">
          <Button
            className="bg-transparent font-semibold text-blue hover:bg-blue hover:text-white justify-center text-md"
            borderColor="blue"
            type="button"
            onClick={() => closeModal()}
          >
            Avbryt
          </Button>
          <Button
            className="bg-blue font-semibold text-white justify-center text-md"
            type="submit"
            onClick={() => requestAction()}
          >
            Skicka
          </Button>
        </div>
      </div>
    </div>
  );
}
