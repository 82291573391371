"use client";

import React, { useEffect, useMemo, useReducer, useRef, useState } from "react";
import useSWR, { mutate } from "swr";
import Checkbox from "@/components/Elements/Checkbox";
import useUserStore from "@/stores/useUserStore";
import Skeleton from "react-loading-skeleton";
import { dismissAllNotifications, fetchNotifications } from "@/actions/user";
import UserNotifications from "./UserNotifications";
import Button from "@/components/Elements/Button";
import { useShallow } from "zustand/react/shallow";
import { NotificationContext } from "./NotificationContext";
import BeatLoader from "react-spinners/BeatLoader";
import "react-loading-skeleton/dist/skeleton.css";

const Notifications = ({ block, blockStyle, extraClass }: BlockProps) => {
  const { user } = useUserStore(useShallow((state) => state));

  const [isMarkingAll, setIsMarkingAll] = useState(false);

  const [paginationArgs, setPaginationArgs] = useState<any>({
    page: 1,
    size: 10,
    total: 0,
  });

  const reducer = (state: any, action: any) => {
    const payload = action.payload;

    switch (action.type) {
      case "ADD_FILTER":
        return payload.map((newFilter: any) => {
          const existingFilter = state.find(
            (item: any) => item.settingsKey === newFilter.settingsKey
          );
          if (existingFilter) {
            return { ...existingFilter, ...newFilter };
          }

          return newFilter;
        });
      case "TOGGLE_FILTER":
        return state.map((item: any) =>
          item.settingsKey === action.settingsKey
            ? { ...item, selected: !item.selected }
            : item
        );
      default:
        return state;
    }
  };

  const [menuState, dispatchMenuState] = useReducer(reducer, []);

  const notificationParentClasses = menuState
    ?.filter((state: any) => state.selected)
    .map((state: any) => state.key);

  const userId = user?.id;

  const markAllRead = async () => {
    setIsMarkingAll(true);

    try {
      const dismsiss = await dismissAllNotifications();

      if (dismsiss !== "OK") {
        throw new Error("Error dismissing notification");
      }

      await mutate("user/getUserNotifications");
    } catch (error) {
      //toast.error("Kunde inte avfärda notifikationen. försök igen senare.");
      console.error("Error dismissing notification", error);
    }
  };

  const fetchKey = [
    "user/getUserNotifications",
    userId,
    paginationArgs?.page,
    notificationParentClasses?.length,
  ];

  const {
    data: notificationsData,
    error,
    isLoading,
  } = useSWR<any, Error>(
    userId ? [fetchKey] : null,
    async () => {
      try {
        const data = await fetchNotifications({
          offset: (paginationArgs.page - 1) * paginationArgs.size,
          limit: paginationArgs.size,
          notificationParentClasses: notificationParentClasses?.length
            ? notificationParentClasses
            : [],
        });

        setPaginationArgs((prev: any) => ({
          ...prev,
          test: 2,
          total: data?.totalHits,
        }));

        setIsMarkingAll(false);

        return data;
      } catch (error) {
        console.error("Error fetching notifications", error);
      }

      return [];
    },
    {
      refreshInterval: 10000,
      revalidateOnFocus: true,
    }
  );

  const totalHits = notificationsData?.totalHits || 0;
  const allowedTypes = notificationsData?.allowedTypes || [];

  const filterSelections = [
    {
      settingsKey: "USER_OUTBIDDED_NOTIFICATION",
      label: "Budhändelser",
      key: "bid",
      selected: true,
    },
  ];

  const allowedTypesCompare = JSON.stringify(allowedTypes);

  useEffect(() => {
    if (isLoading || error) {
      return;
    }

    const newMenuState = filterSelections
      .filter((f) => allowedTypes.includes(f.settingsKey))
      .map((item) => {
        return {
          key: item.key,
          label: item.label,
          settingsKey: item.settingsKey,
          selected: item.selected,
        };
      });

    // Only dispatch if the newMenuState is different
    if (JSON.stringify(menuState) !== JSON.stringify(newMenuState)) {
      dispatchMenuState({ type: "ADD_FILTER", payload: newMenuState });
    }
  }, [allowedTypesCompare]);

  const { notifications, unreadCount } = notificationsData || {};

  if (error) {
    return (
      <div>
        Kunde inte läsa in sidan, försök igen senare eller kontakta Tovek för
        support.
      </div>
    );
  }

  if (!userId) {
    return (
      <div className="flex flex-col space-y-8">
        <Skeleton height={100} containerClassName="flex-1" />
        <Skeleton height={100} containerClassName="flex-1" />
        <Skeleton height={100} containerClassName="flex-1" />
      </div>
    );
  }

  return (
    <NotificationContext.Provider
      value={{
        paginationArgs,
        setPaginationArgs,
        menuState,
        dispatchMenuState,
      }}
    >
      <div className="flex flex-col space-y-8 w-full" style={blockStyle}>
        <div className="flex flex-row items-center justify-between mb-2">
          <h2>Notiser & händelser</h2>
          {unreadCount > 0 &&
            totalHits > 0 &&
            !isLoading &&
            (isMarkingAll ? (
              <BeatLoader color="#183e4f" />
            ) : (
              <Button className="ml-5" onClick={markAllRead}>
                Markera alla som lästa
              </Button>
            ))}
        </div>
        <div className="justify-start items-start space-y-5 flex-col">
          {totalHits > 0 && <span>Visa:</span>}
          {menuState?.map((filter: any, index: number) => {
            return (
              <div
                className="p-2 px-5 bg-aliceblue rounded-sm text-14 font-semibold text-black items-center justify-start space-x-3 flex"
                key={index}
              >
                <span>{filter.label}</span>
                <Checkbox
                  ripple={false}
                  checked={filter.selected}
                  onChange={() => {
                    dispatchMenuState({
                      type: "TOGGLE_FILTER",
                      settingsKey: filter.settingsKey,
                    });
                  }}
                />
              </div>
            );
          })}
        </div>
        {isLoading ? (
          <div className="flex flex-col space-y-4">
            <Skeleton height={30} containerClassName="flex-1" />
            <Skeleton height={30} containerClassName="flex-1" />
          </div>
        ) : (
          <UserNotifications notifications={notifications} />
        )}
      </div>
    </NotificationContext.Provider>
  );
};

export default Notifications;
