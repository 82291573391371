"use client";

import {
  RadioProps as MTRadioProps,
  Radio as MTRadio,
} from "@material-tailwind/react";

interface RAdioProps extends MTRadioProps {}

const Radio: React.FC<RAdioProps> = ({ ...alertProps }) => {
  return <MTRadio {...(alertProps as any)} />;
};

export default Radio;
